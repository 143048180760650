import React from 'react';
import { Img } from 'components/base';
import { AuthorLink, ColumnistLayout } from 'sharedTypes';
import { format } from 'date-fns';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import AuthorDateButtons from 'components/AuthorDateButtons';
import { curlyQuotes } from 'utils/text';
import { Link } from 'components/Link';

interface ColumnistProps {
  columnist: ColumnistLayout | null;
  isSectionPage?: boolean;
}

export const Columnist: React.FC<ColumnistProps> = ({
  columnist,
  isSectionPage,
}) => {
  if (!columnist) return null;
  const {
    article: { authors, releaseDate, title, section, slug },
  } = columnist;

  const hasAffiliatedArticle =
    columnist.article.title !== '' && columnist.article.authors !== null;

  const publishedDate = () => {
    if (releaseDate) {
      return format(new Date(releaseDate), 'MMMM d, yyyy');
    }

    return undefined;
  };

  return (
    <>
      {hasAffiliatedArticle ? (
        <div className="Columnist__article-container flex color-black">
          <div className="Columnist__row flex w100 color-black">
            <div className="Columnist__article-image relative flex circle w100 overflow-hidden">
              <Img
                className="fit-cover w100 h100"
                alt={columnist.image.alt || ''}
                src={sanityImgUtil(columnist.image, 768)}
                srcSet={sanityImgSrcSetUtil(columnist.image, 320, 768)}
                sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px"
                dimensions={columnist.image.metadata?.dimensions}
                crop={columnist.image.crop}
              />
            </div>
            <div className="Columnist__text flex flex-col">
              <p className="Columnist__text__title">
                <span className="graebenbach uppercase linked-border-bottom-black">
                  <Link
                    to={`/columns/${columnist.slug}`}
                    className="Columnist__text__title__link color-black"
                  >
                    {curlyQuotes(columnist.title)}
                  </Link>
                </span>
              </p>
              {section?.slug && slug && title && (
                <Link
                  to={`/sections/${section.slug}/articles/${slug}`}
                  className="Columnist__row__article-button color-black"
                >
                  <span className="Columnist__article-title text-section-title-sm itc-cushing color-black font-600 linked-border-bottom-sm-on-hover-red">
                    {curlyQuotes(title)}
                  </span>
                </Link>
              )}
              {authors && (
                <AuthorDateButtons
                  authors={authors as AuthorLink[]}
                  className="text-article-details-xxxs line-height-xxs"
                  publishedDate={publishedDate()}
                />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
