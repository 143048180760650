import React from 'react';
import cx from 'classnames';
import { curlyQuotes } from '../utils/text';
import get from 'lodash/get';
import { Button } from '../components/base';
import Language from '../constants/Language';
import { Collection } from '../sharedTypes';

interface Props {
  collection: Collection;
  hideSectionIcon?: boolean;
  hideBorder?: boolean;
  hideLink?: boolean;
  slugLink?: boolean;
  hideTitleLink?: boolean;
}

const CollectionTitle: React.FC<Props> = ({
  collection,
  hideBorder = false,
  hideLink = false,
  slugLink = false,
  hideTitleLink = false,
}) => {
  const title = get(collection, 'title');

  return (
    <div
      className={cx('CollectionTitle', {
        'dotted-border-bottom-black': !hideBorder,
      })}
    >
      {slugLink ? (
        <Button
          ariaLabel={Language.t('Global.collection')}
          to={'/collections'}
          wrap={true}
        >
          <div className="graebenbach CollectionTitle__tag">
            {Language.t('Global.collection')}
          </div>
        </Button>
      ) : (
        <div className="graebenbach CollectionTitle__tag">
          {Language.t('Global.collection')}
        </div>
      )}
      <div className={'CollectionTitle__container'}>
        <div className={'CollectionTitle__container__left-col'}>
          {hideTitleLink ? (
            <span
              className={cx(
                'CollectionTitle__container__left-col__title graebenbach'
              )}
            >
              {curlyQuotes(title)}
            </span>
          ) : (
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: title,
              })}
              to={'/collections/' + collection.slug}
              className="h100 w100 color-black"
              containerClassName={cx('itc-cushing block-important')}
              wrap={true}
            >
              <span
                className={cx(
                  'CollectionTitle__container__left-col__title graebenbach linked-border-bottom-md-on-hover-red'
                )}
              >
                {curlyQuotes(title)}
              </span>
            </Button>
          )}
        </div>
        <div className={'CollectionTitle__container__right-col'}>
          <div className="CollectionTitle__container__right-col__slug graebenbach text-section-body-sm font-400">
            {curlyQuotes(`${get(collection, 'description', '')}`)}{' '}
            {!hideLink && (
              <Button
                ariaLabel={Language.t('Global.seeFullCollection')}
                to={'/collections/' + collection.slug}
                wrap={true}
              >
                <div
                  className={
                    'relative color-black flex flex-col items-start sm:block'
                  }
                >
                  <div className="CollectionTitle__container__right-col__slug TextIntroStoryTeaserWidget__continue-reading relative color-black graebenbach text-section-body-sm font-400 inline-block">
                    {Language.t('Global.seeFullCollection')} &rarr;&#xFE0E;
                  </div>
                </div>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionTitle;
