import React from 'react';

import Ad from 'components/Ad';
import { AdSizeLayout } from 'sharedTypes';

interface Props {
  size: AdSizeLayout;
}

const AdLayout: React.FC<Props> = ({ size }) => (
  <div className="AdLayout none md:block mt4">
    <Ad size={size} />
    <div className="inner-content-max-width mxauto content-padding-x mt3">
      <div className="border-bottom-black" />
    </div>
  </div>
);

export default AdLayout;
