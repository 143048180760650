import get from 'lodash/get';
import { format, addMinutes } from 'date-fns';

export interface ShabbatInfoResponse {
  date: string;
  time: string;
  error: string | null;
}

export interface latitudeLongitude {
  latitude: number;
  longitude: number;
}

export const fetchShabbatInfo = async (): Promise<ShabbatInfoResponse> => {
  const { date, time, error } = await fetchShabbatTime();
  return {
    date,
    time,
    error,
  };
};

const fetchIp = async (): Promise<string> => {
  const res = await fetch('https://api.ipify.org?format=json');
  const data = await res.json();
  if (!data?.ip) {
    console.log('API error', data);
  }
  return data.ip;
};

const getCoordinates = async (): Promise<latitudeLongitude> => {
  const ip = await fetchIp();
  const res = await fetch(
    `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.NEXT_PUBLIC_IP_GEOLOCATION_API_KEY}&ip=${ip}`
  );
  const data = await res.json();
  if (!data?.latitude || !data?.longitude) {
    console.log('API error', data);
  }
  return {
    latitude: parseFloat(data.latitude),
    longitude: parseFloat(data.longitude),
  };
};

const fetchShabbatTime = (): Promise<ShabbatInfoResponse> =>
  new Promise((resolve, reject) => {
    getCoordinates()
      .then(async (data) => {
        console.log('refetched');

        const latitude = get(data, 'latitude', 0);
        const longitude = get(data, 'longitude', 0);

        let timezoneId: string | undefined;

        try {
          timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (e) {
          reject(e);
        }

        //Defaults to 18 for candle lighting minutes before sunset
        //Specific Date: &gy=YYYY&gm=MM&gd=DD
        const res = await fetch(
          `https://www.hebcal.com/shabbat/?cfg=json&latitude=${latitude}&longitude=${longitude}&tzid=${timezoneId}&m=0`
        );

        const shabbatData = await res.json();

        const filterByCandleLightings = (shabbatData: { items: any[] }) => {
          const candleLightings = shabbatData.items.filter(
            (item: { category: string }) => item.category === 'candles'
          );

          return candleLightings;
        };

        const getNextDay = () => {
          const currentRangeEnd = new Date(`${shabbatData.range.end}T00:00:00`);

          const nextDay = currentRangeEnd.setDate(
            currentRangeEnd.getDate() + 1
          );

          const formattedNextDay = format(nextDay, 'yyyy-MM-dd').split('-');

          return formattedNextDay;
        };

        const getUpcomingDates = async (shabbatData: { items: any[] }) => {
          const currentDate = new Date();

          const candleLightings = filterByCandleLightings(shabbatData);

          const possibleUpcomingDates = candleLightings.filter(
            (item) => new Date(item.date).getTime() >= currentDate.getTime()
          );

          if (possibleUpcomingDates.length) {
            return possibleUpcomingDates[0].date;
          } else {
            console.log(
              `There are no candle lightings for ${new Date()}. Fetching the upcoming week!`
            );

            const nextDay = getNextDay();

            const res = await fetch(
              `https://www.hebcal.com/shabbat/?cfg=json&latitude=${latitude}&longitude=${longitude}&tzid=${timezoneId}&m=0&gy=${nextDay[0]}&gm=${nextDay[1]}&gd=${nextDay[2]}`
            );
            const shabbatData = await res.json();

            const nextWeeksCandleLighting =
              filterByCandleLightings(shabbatData);

            return nextWeeksCandleLighting[0].date;
          }
        };

        const shabbatDate = await getUpcomingDates(shabbatData);

        if (!shabbatDate) {
          reject();
        }

        const date: Date = new Date(shabbatDate);
        const time = format(date, 'h:mmb');
        const formattedTime = time.replace('AM', ' AM').replace('PM', ' PM'); // add a space between the time and AM/PM

        return resolve({
          date: format(date, 'EEEE, MMMM d'),
          time: formattedTime,
          error: null,
        });
      })
      .catch(reject);
  });

// depcreated as of 2022-12-01 for above API
// const fetchCurrentCity = (): Promise<string> =>
//   new Promise((resolve, reject) => {
//     getCoordinates()
//       .then((data) => {
//         const latitude = get(data, 'latitude', 0);
//         const longitude = get(data, 'longitude', 0);

//         return Geocoder.reverseGeocode({
//           query: [longitude, latitude],
//           mode: 'mapbox.places',
//         })
//           .send()
//           .then((response: any) => {
//             const features = get(response, 'body.features');

//             if (!features.length)
//               reject('We could not determine your location.');

//             const locality: string = get(
//               features.find((feature: unknown) =>
//                 get(feature, 'id', '').includes('locality'),
//               ),
//               'text',
//               '',
//             );
//             const region: string = get(
//               features.find((feature: unknown) =>
//                 get(feature, 'id', '').includes('region'),
//               ),
//               'text',
//               '',
//             );
//             const place: string = get(
//               features.find((feature: unknown) =>
//                 get(feature, 'id', '').includes('place'),
//               ),
//               'text',
//               '',
//             );
//             const city: string = locality || place;

//             return resolve(city ? `${city}, ${region}` : region);
//           });
//       })
//       .catch(reject);
//   });
