import { FC, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Button } from 'components/base';
import { ExploreIcon } from 'components/icons';
import Language from 'constants/Language';
import { curlyQuotes } from 'utils/text';
import { useSiteSettings } from 'providers/SiteSettingsProvider';
import { useScrollPosition, Direction } from 'utils/useScrollPosition';
import { useUI } from 'providers/UIProvider';

interface Props {
  theme?: 'light' | 'dark';
  className?: string;
  handleNextSectionClick?: () => any;
  handlePrevSectionClick?: () => any;
  prevSectionLink?: string;
  nextSectionLink?: string;
  pageLength?: number;
  currentIndex?: number;
}

export const ExploreScrollButton: FC<Props> = (props) => {
  /**
   * Props and State
   */

  const { sideNavIsOpen, openSectionNav } = useUI();
  const {
    theme = 'light',
    className = '',
    handleNextSectionClick,
    handlePrevSectionClick,
    prevSectionLink,
    nextSectionLink,
    pageLength,
    currentIndex,
  } = props;
  const { position, direction, scrollAtBottom } = useScrollPosition();
  const scrollCTATextRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [hideButton, setHideButton] = useState<boolean>(false);

  const { globalSettings } = useSiteSettings();
  const { sectionScrollCTA } = globalSettings;
  // const { hideButton, scrollAtBottom } = this.state;
  /**
   * Effects
   */
  useEffect(() => {
    if (position <= 0) {
      setHideButton(false);
    } else {
      if (direction === Direction.Up) {
        setHideButton(false);
      } else {
        setHideButton(true);
      }
    }
  }, [direction, position]);

  /**
   * Handlers
   */

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleExploreButtonClick = () => {
    openSectionNav();
  };

  const handleNextButtonClick = () => {
    if (!handleNextSectionClick) return;
    handleNextSectionClick();
  };

  const handlePrevButtonClick = () => {
    if (!handlePrevSectionClick) return;
    handlePrevSectionClick();
  };

  const isLightTheme = theme === 'light';
  const prevNextButtonIsOpen =
    handleNextSectionClick &&
    handlePrevSectionClick &&
    typeof pageLength === 'number' &&
    typeof currentIndex === 'number';

  const scrollCTAText =
    sectionScrollCTA || Language.t('Global.exploreTheScroll');

  const ctaTextWidth = scrollCTATextRef.current?.offsetWidth || 0;

  const ctaTextWrapperStyles = {
    width: isHovered ? ctaTextWidth : '0',
    opacity: isHovered ? '1' : '0',
    pointerEvents: isHovered ? ('inherit' as const) : ('none' as const),
  };

  return (
    <>
      {prevNextButtonIsOpen && (
        <Button
          ariaLabel={Language.t('Global.prevSectionButtonAriaLabel')}
          onClick={handlePrevButtonClick}
          to={prevSectionLink}
          className={cx(
            'ExploreScrollButton__prev-next-button md:none fixed l0 z-nav transition',
            'trigger-SectionCarousel-PreviousButton flex justify-center items-center color-black',
            {
              'bg-color-black': !isLightTheme,
              'bg-color-off-white': isLightTheme,
              'ExploreScrollButton__prev-next-button--inactive':
                currentIndex === 0,
              'ExploreScrollButton__prev-next-button--collapsed': hideButton,
              'ExploreScrollButton__prev-next-button--move-up': scrollAtBottom,
              'ExploreScrollButton__prev-next-button--side-nav-is-active':
                sideNavIsOpen,
            }
          )}
        >
          <span className="graebenbach font-100">&larr;&#xFE0E;</span>
        </Button>
      )}
      <div
        className={cx(
          'ExploreScrollButton z-nav fixed r0 l0 mxauto transition flex items-center justify-center no-display-in-print',
          className,
          {
            'ExploreScrollButton--collapsed': hideButton,
            'ExploreScrollButton--move-up': scrollAtBottom,
            'ExploreScrollButton--side-nav-is-active': sideNavIsOpen,
          }
        )}
      >
        <div className="ExploreScrollButton__button-container flex justify-center">
          <Button
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ariaLabel={Language.t('Global.openSectionNav')}
            onClick={handleExploreButtonClick}
            className={cx(
              'ExploreScrollButton__button',
              'trigger-ScrollUsage-ExploreScrollButton',
              {
                'bg-color-black': !isLightTheme,
                'bg-color-off-white': isLightTheme,
              }
            )}
          >
            <div className="relative flex items-center">
              <ExploreIcon
                className="ExploreScrollButton__icon transition-short"
                color={isLightTheme ? 'black' : 'off-white'}
              />
              <div
                className="ExploreScrollButton__textContainer"
                style={ctaTextWrapperStyles}
              >
                <span
                  ref={scrollCTATextRef}
                  className={cx(
                    'ExploreScrollButton__text font-400 graebenbach',
                    {
                      'color-black': isLightTheme,
                      'color-off-white': !isLightTheme,
                    }
                  )}
                >
                  {curlyQuotes(scrollCTAText)}
                </span>
              </div>
            </div>
          </Button>
        </div>
      </div>
      {prevNextButtonIsOpen && (
        <Button
          ariaLabel={Language.t('Global.prevSectionButtonAriaLabel')}
          onClick={handleNextButtonClick}
          to={nextSectionLink}
          className={cx(
            'ExploreScrollButton__prev-next-button md:none fixed r0 transition',
            'trigger-SectionCarousel-NextButton flex justify-center items-center color-black',
            {
              'bg-color-black': !isLightTheme,
              'bg-color-off-white': isLightTheme,
              'ExploreScrollButton__prev-next-button--inactive':
                pageLength && currentIndex === pageLength - 1,
              'ExploreScrollButton__prev-next-button--collapsed': hideButton,
              'ExploreScrollButton__prev-next-button--move-up': scrollAtBottom,
              'ExploreScrollButton__prev-next-button--side-nav-is-active':
                sideNavIsOpen,
            }
          )}
        >
          <span className="graebenbach font-100">&rarr;&#xFE0E;</span>
        </Button>
      )}
    </>
  );
};

export default ExploreScrollButton;
