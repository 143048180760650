import { Tag, TagLink } from 'sharedTypes';
import slugify from 'utils/slugify';

import { RouteMap } from 'constants/RouteMap';

const generateTagUrl = (tag: Tag | TagLink): string => {
  if (!tag || (!tag.title && !tag.slug)) return '';

  return `${RouteMap.TAGS.base}${tag.slug || slugify(tag.title)}`;
};

export default generateTagUrl;
