import { ColumnsCarousel } from 'components/ColumnsCarousel';
import ApiClient from 'lib/ApiClient';
import React, { useEffect } from 'react';
import { ArticleLink, ColumnLayout as IColumnsLayout } from 'sharedTypes';

interface Props {
  layoutModule: IColumnsLayout;
}

const ColumnsLayout: React.FC<Props> = ({ layoutModule }) => (
  <div className="ColumnsLayout content-padding-x sm:pt1_5 inner-content-max-width mxauto">
    <ColumnsCarousel column={layoutModule.column} />
  </div>
);
export default ColumnsLayout;
