import React from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';

import { Collections8Stories as ICollections8Stories } from 'sharedTypes';
import CollectionTitle from 'components/CollectionTitle';
import cx from 'classnames';

interface Props {
  layoutModule: ICollections8Stories;
  hideSectionIcon?: boolean;
  isSectionPage?: boolean;
}

const Collections8Stories: React.FC<Props> = ({
  layoutModule,
  hideSectionIcon,
  isSectionPage,
}) => (
  <div className="content-padding-x inner-content-max-width mxauto pb3">
    <div
      className={cx('collections-4-stories', {
        'collection-background-grey': !isSectionPage,
        'collection-background-grey-section': isSectionPage,
      })}
    >
      <div className={'collections-container'}>
        <CollectionTitle
          collection={layoutModule.collectionsLayoutLibrary.collection}
          slugLink={true}
        />
        <div className={'collections-container__col-left'}>
          <div className="collections-4-stories__container  inner-content-max-width mxauto">
            <div className="collections-4-stories__container__article1-container layout-story-container">
              <StoryTeaserSwitch
                teaser={
                  layoutModule.collectionsLayoutLibrary.article1TeaserType
                }
                article={layoutModule.collectionsLayoutLibrary.article1}
                hideSectionIcon={hideSectionIcon}
              />
            </div>
            <div className="collections-4-stories__container__article2-article3-container relative">
              <div className="overflow-hidden non-main-teaser col1">
                <StoryTeaserSwitch
                  teaser={
                    layoutModule.collectionsLayoutLibrary.article2TeaserType
                  }
                  article={layoutModule.collectionsLayoutLibrary.article2}
                  hideSectionIcon={hideSectionIcon}
                  isSplitLayout={true}
                />
              </div>
              <div className="overflow-hidden non-main-teaser col2">
                <StoryTeaserSwitch
                  teaser={
                    layoutModule.collectionsLayoutLibrary.article3TeaserType
                  }
                  article={layoutModule.collectionsLayoutLibrary.article3}
                  hideSectionIcon={hideSectionIcon}
                  isSplitLayout={true}
                />
              </div>
            </div>
            <div className="collections-4-stories__container__article2-container layout-story-container none sm:block non-main-teaser">
              <StoryTeaserSwitch
                teaser={
                  layoutModule.collectionsLayoutLibrary.article2TeaserType
                }
                article={layoutModule.collectionsLayoutLibrary.article2}
                hideSectionIcon={hideSectionIcon}
              />
            </div>
            <div className="collections-4-stories__container__article3-container layout-story-container none sm:block non-main-teaser">
              <StoryTeaserSwitch
                teaser={
                  layoutModule.collectionsLayoutLibrary.article3TeaserType
                }
                article={layoutModule.collectionsLayoutLibrary.article3}
                hideSectionIcon={hideSectionIcon}
              />
            </div>
            <div className="collections-4-stories__container__article4-container">
              <StoryTeaserSwitch
                teaser={
                  layoutModule.collectionsLayoutLibrary.article4TeaserType
                }
                article={layoutModule.collectionsLayoutLibrary.article4}
                hideSectionIcon={hideSectionIcon}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="collections-8-stories inner-content-max-width mxauto">
        <div className="collections-8-stories__article1-container">
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article5TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article5}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
        <div className="collections-8-stories__article2-container">
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article6TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article6}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
        <div className="collections-8-stories__article3-container">
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article7TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article7}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
        <div className="collections-8-stories__article4-container">
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article8TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article8}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
      </div>
    </div>
  </div>
);
export default Collections8Stories;
