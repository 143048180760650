import React from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';

import { LayoutK as ILayoutK } from 'sharedTypes';

interface Props {
  layoutModule: ILayoutK;
  hideSectionIcon?: boolean;
}

const LayoutK: React.FC<Props> = ({ layoutModule, hideSectionIcon }) => (
  <div className="LayoutK content-padding-x sm:pt1_5 inner-content-max-width mxauto">
    <div className="LayoutK__article1-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article1TeaserType}
        article={layoutModule.article1}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutK__article2-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article2TeaserType}
        article={layoutModule.article2}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutK__article3-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article3TeaserType}
        article={layoutModule.article3}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutK__article4-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article4TeaserType}
        article={layoutModule.article4}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
  </div>
);

export default LayoutK;
