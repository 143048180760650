import React from 'react';

import DonationWidget from 'components/widgets/DonationWidget';
import DownloadAppWidget from '../components/widgets/DownloadAppWidget';
import EncyclopediaWidget from 'components/widgets/EncyclopediaWidget';
import EventWidget from 'components/widgets/EventWidget';
import FactWidget from 'components/widgets/FactWidget';
import AuthorWidget from 'components/widgets/AuthorWidget';
import ListWidget from 'components/widgets/ListWidget';
import NewsletterWidget from 'components/widgets/NewsletterWidget';
import PeopleSlideshowWidget from 'components/widgets/PeopleSlideshowWidget';
import PhotoSlideshowWidget from 'components/widgets/PhotoSlideshowWidget';
import PodcastWidget from 'components/widgets/PodcastWidget';
import UpcomingShabbatWidget from 'components/widgets/UpcomingShabbatWidget';
import TagWidget from 'components/widgets/TagWidget';
import TextWidget from 'components/widgets/TextWidget';

import { Widget } from 'sharedTypes';
import ColumnistWidget from './widgets/ColumnistWidget/ColumnistWidget';

interface Props {
  className?: string;
  widget: Widget;
  isSectionPage?: boolean;
  isNarrow?: boolean;
}

const WidgetSwitch: React.FC<Props> = ({
  className = '',
  widget,
  isSectionPage,
  isNarrow,
}) => {
  switch (widget.type) {
    case 'donationWidget':
      return <DonationWidget className={className} widget={widget} />;
    case 'downloadAppWidget':
      return <DownloadAppWidget className={className} widget={widget} />;
    case 'encyclopediaWidget':
      return <EncyclopediaWidget className={className} widget={widget} />;
    case 'eventWidget':
      return (
        <EventWidget
          className={className}
          widget={widget}
          isSectionPage={isSectionPage}
        />
      );
    case 'factWidget':
      return <FactWidget className={className} widget={widget} />;
    case 'authorWidget':
      return (
        <AuthorWidget
          className={className}
          widget={widget}
          isSectionPage={isSectionPage}
        />
      );
    case 'listWidget':
      return <ListWidget className={className} widget={widget} />;
    case 'newsletterWidget':
      return (
        <NewsletterWidget
          className={className}
          widget={widget}
          isSectionPage={isSectionPage}
          isNarrow={isNarrow}
        />
      );
    case 'peopleSlideshowWidget':
      return <PeopleSlideshowWidget className={className} widget={widget} />;
    case 'photoSlideshowWidget':
      return <PhotoSlideshowWidget className={className} widget={widget} />;
    case 'podcastWidget':
      return (
        <PodcastWidget
          className={className}
          widget={widget}
          isSectionPage={isSectionPage}
        />
      );
    case 'tagWidget':
      return <TagWidget className={className} widget={widget} />;
    case 'shabbatWidget':
      return <UpcomingShabbatWidget className={className} widget={widget} />;
    case 'textWidget':
      return <TextWidget className={className} widget={widget} />;
    case 'columnistWidget':
      return <ColumnistWidget className={className} widget={widget} />;
    default:
      console.warn('Widget not found for this widget:', widget);
      return null;
  }
};

export default WidgetSwitch;
