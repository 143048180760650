import Language from 'constants/Language';

const truncate = (
  text: string,
  length: number,
  trailingString: string = Language.t('Global.truncatedTrailingString')
) => {
  if (text.length <= length) {
    return text;
  }

  return text.slice(0, length) + trailingString;
};

export default truncate;
