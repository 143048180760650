import React, { Component } from 'react';
import cx from 'classnames';
import Language from 'constants/Language';
import generateCountdownInWords from 'utils/generateCountdownInWords';

interface Props {
  className?: string;
  startDate: string;
  endDate: string;
  isSectionNav?: boolean;
}

interface State {
  countdown: string;
}

class HolidayCountdown extends Component<Props, State> {
  private timerId: number | undefined = 0;

  state = {
    countdown: '',
  };

  componentDidMount() {
    this.setCountdown(this.props.startDate, this.props.endDate);

    this.timerId = window.setInterval(() => {
      this.setCountdown(this.props.startDate, this.props.endDate);
    }, 60 * 1000);
  }

  componentWillUnmount() {
    window.clearInterval(this.timerId);
  }

  setCountdown = (startDate: string, endDate: string) => {
    const now = new Date().toISOString();

    if (now < startDate) {
      const currentCountdown = `${generateCountdownInWords(startDate)} ${
        this.props.isSectionNav
          ? Language.t('Holidays.holidayCountdown.sundown')
          : Language.t('Holidays.holidayCountdown.away')
      }`;

      return this.setState({ countdown: currentCountdown });
    }

    if (now >= startDate && now < endDate) {
      const currentCountdown = `${generateCountdownInWords(
        endDate
      )} ${Language.t('Holidays.holidayCountdown.left')}`;

      return this.setState({ countdown: currentCountdown });
    }
  };

  render() {
    const { className } = this.props;

    return (
      <div
        className={cx(
          'HolidayCountdown graebenbach font-400 text-center',
          className
        )}
      >
        {this.state.countdown}
      </div>
    );
  }
}

export default HolidayCountdown;
