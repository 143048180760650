import React from 'react';
import { Link } from 'components/Link';
import cx from 'classnames';
import { format } from 'date-fns';
import get from 'lodash/get';
import generatePodcastEpisodeUrl from 'utils/generatePodcastEpisodeUrl';
import { Img, Button } from 'components/base';
import Language from 'constants/Language';
import { WidgetPodcast, PodcastEpisode } from 'sharedTypes';
import { curlyQuotes } from 'utils/text';

interface PodcastWidgetEpisodeProps {
  podcastEpisode: PodcastEpisode;
  widget: WidgetPodcast;
  isSectionPage?: boolean;
}

export const PodcastWidgetEpisode: React.FC<PodcastWidgetEpisodeProps> = ({
  podcastEpisode,
  widget,
  isSectionPage,
}) => {
  const { variant } = widget;
  const podcast = podcastEpisode.podcast;
  const podcastTitle = get(podcast, 'title', '');
  const episodeTitle = get(podcastEpisode, 'title', '');
  const date =
    get(podcastEpisode, 'date') ||
    get(podcastEpisode, 'releaseDate') ||
    get(podcastEpisode, 'createdAt', '');

  const episodeUrl = generatePodcastEpisodeUrl(
    podcastEpisode.podcast.slug,
    podcastEpisode.slug
  );
  const key = `${podcastTitle}-${episodeTitle}`;
  return (
    <div
      key={key}
      className="PodcastWidget__podcast-container py1_25 flex items-start"
    >
      <Button
        ariaLabel={Language.t('Global.podcastButtonAriaLabel', {
          title: episodeTitle,
        })}
        className="p0 bg-color-transparent"
        to={episodeUrl}
      >
        {variant === 'red' && (
          <Img
            className="PodcastWidget__sound-icon"
            alt={Language.t('Global.podcastButtonAriaLabel', {
              title: episodeTitle,
            })}
            src="/assets/images/sound-icon-red.svg"
          />
        )}
        {variant === 'black' && (
          <Img
            className="PodcastWidget__sound-icon"
            alt={Language.t('Global.podcastButtonAriaLabel', {
              title: episodeTitle,
            })}
            src={
              isSectionPage
                ? '/assets/images/sound-icon-beige.svg'
                : '/assets/images/sound-icon-black.svg'
            }
          />
        )}
      </Button>
      <div className="ml_75 graebenbach font-400">
        <p
          className={cx('uppercase text-section-details-xs', {
            'color-gray-dark': variant === 'black',
          })}
        >
          <Link to={episodeUrl}>{curlyQuotes(podcastTitle)}</Link>
        </p>
        <p className="PodcastWidget__podcast-title text-section-body-sm font-600">
          <Link to={episodeUrl}>{curlyQuotes(episodeTitle)}</Link>
        </p>
        <p className="text-section-body-xs">
          {curlyQuotes(get(podcastEpisode, 'dek', ''))}
        </p>
        <p
          className={cx('uppercase text-section-details-xs', {
            'color-gray-dark': variant === 'black',
          })}
        >
          {format(new Date(date), 'MMMM d, y')}
        </p>
      </div>
    </div>
  );
};

interface Props {
  className?: string;
  widget: WidgetPodcast;
  isSectionPage?: boolean;
}

const PodcastWidget: React.FC<Props> = ({
  className = '',
  widget,
  isSectionPage,
}) => {
  const { variant, podcastEpisodes } = widget;

  return (
    <div
      className={cx(
        `PodcastWidget PodcastWidget--style-${variant}`,
        className,
        {
          'PodcastWidget--style-beige': variant === 'black' && isSectionPage,
          'widget-container-black': variant === 'black',
          'widget-container-red': variant === 'red',
        }
      )}
    >
      <div
        className={cx({
          'dotted-border-beige': variant === 'black' && !isSectionPage,
          'dotted-border-off-white': variant === 'black' && isSectionPage,
          'dotted-border-red': variant === 'red',
        })}
      >
        <p className="pb1 uppercase itc-cushing text-section-body-md letter-spacing-sm font-600">
          {Language.t('Widgets.PodcastWidget.title')}
        </p>
      </div>
      {podcastEpisodes &&
        podcastEpisodes.map((podcastEpisode) => (
          <PodcastWidgetEpisode
            key={podcastEpisode.id}
            podcastEpisode={podcastEpisode}
            widget={widget}
            isSectionPage={isSectionPage}
          />
        ))}
    </div>
  );
};

export default PodcastWidget;
