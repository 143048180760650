import React from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';

import { LayoutH as ILayoutH } from 'sharedTypes';

interface Props {
  layoutModule: ILayoutH;
  hideSectionIcon?: boolean;
}

const LayoutH: React.FC<Props> = ({ layoutModule, hideSectionIcon }) => (
  <div className="LayoutH content-padding-x sm:pt1_5 inner-content-max-width mxauto">
    <div className="LayoutH__article1-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article1TeaserType}
        article={layoutModule.article1}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutH__article2-container layout-story-container none sm:block non-main-teaser">
      <StoryTeaserSwitch
        teaser={layoutModule.article2TeaserType}
        article={layoutModule.article2}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutH__article3-container layout-story-container none sm:block non-main-teaser">
      <StoryTeaserSwitch
        teaser={layoutModule.article3TeaserType}
        article={layoutModule.article3}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutH__article4-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article4TeaserType}
        article={layoutModule.article4}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutH__article2-article3-container relative">
      <div className="overflow-hidden non-main-teaser">
        <StoryTeaserSwitch
          teaser={layoutModule.article2TeaserType}
          article={layoutModule.article2}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={true}
        />
      </div>
      <div className="overflow-hidden non-main-teaser">
        <StoryTeaserSwitch
          teaser={layoutModule.article3TeaserType}
          article={layoutModule.article3}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={true}
        />
      </div>
    </div>
  </div>
);
export default LayoutH;
