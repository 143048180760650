import React from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';
import WidgetSwitch from 'components/WidgetSwitch';

import { LayoutI as ILayoutI } from 'sharedTypes';

interface Props {
  layoutModule: ILayoutI;
  hideSectionIcon?: boolean;
  isSectionPage?: boolean;
}

const LayoutI: React.FC<Props> = ({
  layoutModule,
  hideSectionIcon,
  isSectionPage,
}) => (
  <div className="LayoutI content-padding-x sm:pt1_5 inner-content-max-width mxauto">
    <div className="LayoutI__article1-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article1TeaserType}
        article={layoutModule.article1}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutI__article2-container layout-story-container none sm:block lg:block">
      <StoryTeaserSwitch
        teaser={layoutModule.article2TeaserType}
        article={layoutModule.article2}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutI__article3-container layout-story-container none sm:block lg:block">
      <StoryTeaserSwitch
        teaser={layoutModule.article3TeaserType}
        article={layoutModule.article3}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutI__widget1-container layout-widget-container none lg:block">
      <WidgetSwitch
        className="layout-sticky-widget"
        widget={layoutModule.widget1}
        isSectionPage={isSectionPage}
        isNarrow={true}
      />
    </div>
    <div className="LayoutI__widget2-container layout-widget-container none lg:block">
      <WidgetSwitch
        className="layout-sticky-widget"
        widget={layoutModule.widget2}
        isSectionPage={isSectionPage}
        isNarrow={true}
      />
    </div>
    <div className="LayoutI__widget1-widget2-container layout-widget-container lg:none flex-col">
      <div className="layout-sticky-widget layout-widgets-container">
        <WidgetSwitch
          widget={layoutModule.widget1}
          isSectionPage={isSectionPage}
          isNarrow={true}
        />
        <WidgetSwitch
          widget={layoutModule.widget2}
          isSectionPage={isSectionPage}
          isNarrow={true}
        />
      </div>
    </div>
    <div className="LayoutI__article2-article3-container relative layout-story-container sm:none">
      <div className="overflow-hidden non-main-teaser">
        <StoryTeaserSwitch
          teaser={layoutModule.article2TeaserType}
          article={layoutModule.article2}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={true}
        />
      </div>
      <div className="overflow-hidden non-main-teaser">
        <StoryTeaserSwitch
          teaser={layoutModule.article3TeaserType}
          article={layoutModule.article3}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={true}
        />
      </div>
    </div>
  </div>
);

export default LayoutI;
