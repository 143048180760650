import React from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';

import { LayoutO as ILayoutO } from 'sharedTypes';

interface Props {
  layoutModule: ILayoutO;
  hideSectionIcon?: boolean;
}

const LayoutO: React.FC<Props> = ({ layoutModule, hideSectionIcon }) => (
  <div className="LayoutO content-padding-x sm:pt1_5 inner-content-max-width mxauto">
    <div className="LayoutO__article1-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article1TeaserType}
        article={layoutModule.article1}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutO__article2-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article2TeaserType}
        article={layoutModule.article2}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
  </div>
);

export default LayoutO;
