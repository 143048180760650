import React, { Component } from 'react';
import { curlyQuotes } from '../../utils/text';
import cx from 'classnames';
import { BlockContent } from '../../components/BlockContent';
import sanityImgUtil from '../../utils/sanityImgUtil';
import sanityImgSrcSetUtil from '../../utils/sanityImgSrcSetUtil';
import { ImgCarousel, Img } from '../../components/base';
import { WidgetPeopleSlideshow } from '../../sharedTypes';

interface Props {
  className?: string;
  widget: WidgetPeopleSlideshow;
}

interface State {
  imagesHasLoaded: boolean;
  loadedImg: boolean[];
}

class PeopleSlideshowWidget extends Component<Props, State> {
  state: State = {
    imagesHasLoaded: false,
    loadedImg: [],
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      widget: { slides },
    } = this.props;
    const { loadedImg } = this.state;

    if (
      slides.length === prevProps.widget.slides.length &&
      prevState.loadedImg.length !== loadedImg.length
    ) {
      this.setState({ imagesHasLoaded: true });
    }
  }

  handleImgLoad = () => {
    this.setState((state) => ({ loadedImg: state.loadedImg.concat([true]) }));
  };

  render() {
    const {
      className,
      widget: { slides, title },
    } = this.props;
    const { imagesHasLoaded } = this.state;

    return (
      <div
        className={cx(
          'PeopleSlideshowWidget widget-container-red color-red flex flex-col items-center',
          className
        )}
      >
        {title && (
          <p className="w100 pb_5 mb1_25 uppercase dotted-border-red text-center itc-cushing text-section-title-xs font-800">
            {curlyQuotes(title)}
          </p>
        )}
        <ImgCarousel
          pageDots={slides.length > 1}
          imagesHasLoaded={imagesHasLoaded}
          itemLength={slides && slides.length}
        >
          {slides &&
            slides.map((slide, index) => {
              return (
                <div
                  key={`slide-${index}-${slide._key}`}
                  className="w100 px1_5"
                >
                  <div className="img-container relative circle aspect-square mb_5 w100 overflow-hidden">
                    <Img
                      className="grayscale-img w100 h100 fit-cover block circle aspect-square"
                      alt={slide.image.alt || slide.image.caption || ''}
                      onImgLoad={this.handleImgLoad}
                      sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 360px, 900px"
                      src={sanityImgUtil(slide.image, 900)}
                      srcSet={sanityImgSrcSetUtil(slide.image, 768, 360, 900)}
                      dimensions={
                        slide.image.metadata && slide.image.metadata.dimensions
                      }
                      crop={slide.image.crop}
                    />
                    <Img
                      className="color-img absolute t0 b0 r0 l0 w100 h100 fit-cover block circle aspect-square"
                      alt={slide.image.alt || slide.image.caption || ''}
                      sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 360px, 900px"
                      src={sanityImgUtil(slide.image, 900)}
                      dimensions={
                        slide.image.metadata && slide.image.metadata.dimensions
                      }
                      srcSet={sanityImgSrcSetUtil(slide.image, 768, 360, 900)}
                    />
                  </div>
                  {slide.image.rteCredit ? (
                    <span className="ImageCreditLink PeopleSlideshowWidget__image-credit-link color-gray text-center text-article-details-xxxs font-400 uppercase graebenbach letter-spacing-md transition-shorter mb3">
                      <BlockContent blocks={slide.image.rteCredit} />
                    </span>
                  ) : slide.image.credit ? (
                    <p className="PeopleSlideshowWidget__image-credit text-center color-gray text-article-details-xxxs font-400 uppercase graebenbach letter-spacing-md mb3">
                      {curlyQuotes(slide.image.credit)}
                    </p>
                  ) : (
                    ''
                  )}
                  {slide.title && (
                    <p className="mb_25 text-center uppercase itc-cushing text-section-details-sm font-800">
                      {curlyQuotes(slide.title)}
                    </p>
                  )}
                  {slide.subtitle && (
                    <p className="mb_25 text-center graebenbach text-section-body-xs font-400">
                      {curlyQuotes(slide.subtitle)}
                    </p>
                  )}
                  {slide.text && (
                    <div
                      className={cx(
                        'mb_5 text-center widget-portable-text graebenbach text-section-body-xs font-400',
                        {
                          mt1: slide.title && slide.subtitle,
                        }
                      )}
                    >
                      <div>
                        <BlockContent blocks={slide.text} />
                      </div>
                    </div>
                  )}
                  {slide.attribution && (
                    <p className="mt1 mb_5 text-center graebenbach font-700">
                      {curlyQuotes(slide.attribution)}
                    </p>
                  )}
                </div>
              );
            })}
        </ImgCarousel>
      </div>
    );
  }
}

export default PeopleSlideshowWidget;
