import React from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';

import { LayoutJ as ILayoutJ } from 'sharedTypes';

interface Props {
  layoutModule: ILayoutJ;
  hideSectionIcon?: boolean;
}

const LayoutJ: React.FC<Props> = ({ layoutModule, hideSectionIcon }) => (
  <div className="LayoutJ content-padding-x sm:pt1_5 inner-content-max-width mxauto">
    <div className="LayoutJ__article1-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article1TeaserType}
        article={layoutModule.article1}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutJ__article2-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article2TeaserType}
        article={layoutModule.article2}
        hideSectionIcon={hideSectionIcon}
      />
    </div>

    <div className="LayoutJ__article3-container layout-story-container none md:block">
      <StoryTeaserSwitch
        teaser={layoutModule.article3TeaserType}
        article={layoutModule.article3}
        hideSectionIcon={hideSectionIcon}
      />
    </div>

    <div className="LayoutJ__article3-article4-container grid sm:none relative">
      <div className="overflow-hidden flex-grow">
        <StoryTeaserSwitch
          teaser={layoutModule.article3TeaserType}
          article={layoutModule.article3}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={true}
        />
      </div>
      <div className="overflow-hidden sm:mt1_5 flex-grow">
        <StoryTeaserSwitch
          teaser={layoutModule.article4TeaserType}
          article={layoutModule.article4}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={true}
        />
      </div>
    </div>
    <div className="LayoutJ__article5-article6-container grid sm:none relative">
      <div className="overflow-hidden flex-grow">
        <StoryTeaserSwitch
          teaser={layoutModule.article5TeaserType}
          article={layoutModule.article5}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={true}
        />
      </div>
      <div className="overflow-hidden sm:mt1_5 flex-grow">
        <StoryTeaserSwitch
          teaser={layoutModule.article6TeaserType}
          article={layoutModule.article6}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={true}
        />
      </div>
    </div>

    <div className="LayoutJ__article3-article4-article5-article6-container none sm:grid md:none relative">
      <StoryTeaserSwitch
        teaser={layoutModule.article3TeaserType}
        article={layoutModule.article3}
        hideSectionIcon={hideSectionIcon}
      />
      <StoryTeaserSwitch
        teaser={layoutModule.article5TeaserType}
        article={layoutModule.article5}
        hideSectionIcon={hideSectionIcon}
      />
      <StoryTeaserSwitch
        teaser={layoutModule.article4TeaserType}
        article={layoutModule.article4}
        hideSectionIcon={hideSectionIcon}
      />
      <StoryTeaserSwitch
        teaser={layoutModule.article6TeaserType}
        article={layoutModule.article6}
        hideSectionIcon={hideSectionIcon}
      />
    </div>

    <div className="LayoutJ__article4-article5-article6-container none xl:flex flex-col">
      <div className="layout-story-container flex-grow">
        <StoryTeaserSwitch
          teaser={layoutModule.article4TeaserType}
          article={layoutModule.article4}
          hideSectionIcon={hideSectionIcon}
        />
      </div>
      <div className="layout-story-container xl:mt1_5 flex-grow">
        <StoryTeaserSwitch
          teaser={layoutModule.article5TeaserType}
          article={layoutModule.article5}
          hideSectionIcon={hideSectionIcon}
        />
      </div>
      <div className="layout-story-container xl:mt1_5 flex-grow">
        <StoryTeaserSwitch
          teaser={layoutModule.article6TeaserType}
          article={layoutModule.article6}
          hideSectionIcon={hideSectionIcon}
        />
      </div>
    </div>
  </div>
);

export default LayoutJ;
