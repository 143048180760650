import React, { Component } from 'react';
import cx from 'classnames';
import { BlockContent } from '../../components/BlockContent';
import sanityImgUtil from '../../utils/sanityImgUtil';
import sanityImgSrcSetUtil from '../../utils/sanityImgSrcSetUtil';
import { ImgCarousel, Img } from '../../components/base';
import { WidgetPhotoSlideshow } from '../../sharedTypes';
import { curlyQuotes } from '../../utils/text';

interface Props {
  className?: string;
  widget: WidgetPhotoSlideshow;
}

interface State {
  imagesHasLoaded: boolean;
  loadedImg: boolean[];
}

class PhotoSlideshowWidget extends Component<Props, State> {
  state: State = {
    imagesHasLoaded: false,
    loadedImg: [],
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      widget: { slides },
    } = this.props;
    const { loadedImg } = this.state;

    if (
      slides &&
      slides.length === prevProps.widget.slides.length &&
      prevState.loadedImg.length !== loadedImg.length
    ) {
      this.setState({ imagesHasLoaded: true });
    }
  }

  handleImgLoad = () => {
    this.setState((state) => ({ loadedImg: state.loadedImg.concat([true]) }));
  };

  render() {
    const {
      className,
      widget: { slides, text, title },
    } = this.props;
    const { imagesHasLoaded } = this.state;

    return (
      <div
        className={cx(
          'PhotoSlideshowWidget widget-container-red color-red flex flex-col items-center',
          className
        )}
      >
        {(title || text) && (
          <div className="PhotoSlideshowWidget__header w100">
            {title && (
              <p className="w100 pb_5 mb_75 dotted-border-red uppercase text-center itc-cushing text-section-title-xs font-800">
                {curlyQuotes(title)}
              </p>
            )}
            {text && (
              <div className="mt_25 mb1 widget-portable-text graebenbach text-section-body-xs font-400">
                <BlockContent blocks={text} />
              </div>
            )}
          </div>
        )}
        <ImgCarousel
          imagesHasLoaded={imagesHasLoaded}
          pageDots={slides && slides.length > 1}
          itemLength={slides && slides.length}
        >
          {slides &&
            slides.map((slide, index) => {
              if (!slide.image && !slide.caption) return null;

              return (
                <div
                  key={`slide-${index}-${slide._key}`}
                  className="PhotoSlideshowWidget__slide relative w100 mr1_25 radius-xs overflow-hidden"
                >
                  {slide.image && (
                    <div className="img-container bg-color-red relative w100 radius-xs overflow-hidden mb_5">
                      <Img
                        key={`gray-img-${slide.image.src}`}
                        className="grayscale-img w100 block radius-xs"
                        alt={slide.image.alt || slide.image.caption || ''}
                        onImgLoad={this.handleImgLoad}
                        src={sanityImgUtil(slide.image, 900)}
                        srcSet={sanityImgSrcSetUtil(slide.image, 768, 360, 900)}
                        sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 360, 900px"
                        dimensions={slide.image.metadata?.dimensions}
                        crop={slide.image.crop}
                      />
                      <Img
                        key={`color-img-${slide.image.src}`}
                        className="color-img absolute t0 b0 r0 l0 w100 block radius-xs"
                        alt={slide.image.alt || slide.image.caption || ''}
                        src={sanityImgUtil(slide.image, 900)}
                        srcSet={sanityImgSrcSetUtil(slide.image, 768, 360, 900)}
                        dimensions={slide.image.metadata?.dimensions}
                        sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 360, 900px"
                      />
                    </div>
                  )}
                  {slide.image && slide.image.rteCredit ? (
                    <span className="ImageCreditLink PhotoSlideshowWidget__image-credit-link color-gray text-article-details-xxxs font-400 uppercase graebenbach letter-spacing-md transition-shorter">
                      <BlockContent blocks={slide.image.rteCredit} />
                    </span>
                  ) : slide.image.credit ? (
                    <p className="PhotoSlideshowWidget__image-credit-link color-gray text-article-details-xxxs font-400 uppercase graebenbach letter-spacing-md">
                      {curlyQuotes(slide.image.credit)}
                    </p>
                  ) : (
                    ''
                  )}
                  {slide.caption && (
                    <div
                      className={cx(
                        'PhotoSlideshowWidget__caption mb_5 widget-portable-text graebenbach text-section-body-xs font-400'
                      )}
                    >
                      <div>
                        <BlockContent blocks={slide.caption} />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </ImgCarousel>
      </div>
    );
  }
}

export default PhotoSlideshowWidget;
