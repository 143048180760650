import { useEffect, useReducer } from 'react';
import { Status } from 'types';

/**
 * Some helper types
 */
type AsyncRequest = (...params: any[]) => Promise<any>;

type AsyncReturnValue<T extends AsyncRequest> = ReturnType<T> extends Promise<
  infer R
>
  ? R
  : never;

/**
 * Actions
 */
enum ActionType {
  Start = 'START',
  Fulfilled = 'FULFILLED',
  Rejected = 'REJECTED',
}

interface StartAction {
  type: ActionType.Start;
}

interface FulfilledAction<T> {
  type: ActionType.Fulfilled;
  data: T;
}

interface RejectedAction {
  type: ActionType.Rejected;
  errorMessage: string;
}

type Action<T> = FulfilledAction<T> | RejectedAction;

/**
 * State
 */
interface IState<T> {
  status: Status;
  data?: null | T;
  errorMessage?: string;
}

interface LoadingState<T> extends IState<T> {
  status: Status.PENDING;
  data: null;
}
interface CompletedState<T> extends IState<T> {
  status: Status.FULFILLED;
  data: T;
}
interface RejectedState<T> extends IState<T> {
  status: Status.REJECTED;
  data: null;
  errorMessage: string;
}

type ApiRequestState<T> =
  | LoadingState<T>
  | CompletedState<T>
  | RejectedState<T>;

const reducer = <T>(
  state: ApiRequestState<T>,
  action: Action<T>
): ApiRequestState<T> => {
  switch (action.type) {
    case ActionType.Fulfilled:
      return {
        ...state,
        status: Status.FULFILLED,
        data: action.data,
      };
    case ActionType.Rejected:
      return {
        ...state,
        status: Status.REJECTED,
        data: null,
        errorMessage: action.errorMessage,
      };
  }
};

export const useApiRequest = <
  R extends AsyncRequest,
  P extends Parameters<R>,
  T extends AsyncReturnValue<R>
>(
  callback: R,
  ...params: P
) => {
  const initialState: LoadingState<T> = {
    status: Status.PENDING,
    data: null,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await callback(...params);
        dispatch({ type: ActionType.Fulfilled, data });
      } catch (e) {
        const errorMessage =
          e instanceof Error
            ? e.message
            : 'There was a problem making this request';
        dispatch({ type: ActionType.Rejected, errorMessage });
      }
    };
    fetch();
  }, [callback, params]);

  // Why doesn't useReducer know what this is?
  return state as ApiRequestState<T>;
};
