import LayoutA from 'components/layoutModules/LayoutA';
import LayoutB from 'components/layoutModules/LayoutB';
import LayoutC from 'components/layoutModules/LayoutC';
import LayoutD from 'components/layoutModules/LayoutD';
import LayoutE from 'components/layoutModules/LayoutE';
import LayoutF from 'components/layoutModules/LayoutF';
import LayoutG from 'components/layoutModules/LayoutG';
import LayoutH from 'components/layoutModules/LayoutH';
import LayoutI from 'components/layoutModules/LayoutI';
import LayoutJ from 'components/layoutModules/LayoutJ';
import LayoutK from 'components/layoutModules/LayoutK';
import LayoutL from 'components/layoutModules/LayoutL';
import LayoutM from 'components/layoutModules/LayoutM';
import LayoutN from 'components/layoutModules/LayoutN';
import LayoutO from 'components/layoutModules/LayoutO';
import LayoutHero1 from 'components/layoutModules/LayoutHero1';
import LayoutHero2 from 'components/layoutModules/LayoutHero2';
import LayoutHero3 from 'components/layoutModules/LayoutHero3';
import LayoutHero4 from 'components/layoutModules/LayoutHero4';
import LayoutHolidayHero from 'components/layoutModules/LayoutHolidayHero';
import NewsletterWidget from 'components/widgets/NewsletterWidget';
import AdLayout from 'components/layoutModules/AdLayout';

import { LayoutModule } from 'sharedTypes';
import Collections4Stories from './layoutModules/collections/4Stories';
import Collections4StoriesWidget from './layoutModules/collections/4StoriesWidget';
import Collections6Stories from './layoutModules/collections/6Stories';
import Collections6StoriesWidget from './layoutModules/collections/6StoriesWidget';
import Collections8Stories from './layoutModules/collections/8Stories';
import Collections8StoriesWidget from './layoutModules/collections/8StoriesWidget';
import Collections10Stories from './layoutModules/collections/10Stories';
import Collections10StoriesWidget from './layoutModules/collections/10StoriesWidget';
import ColumnLayout from './layoutModules/ColumnLayout';

interface Props {
  modules: LayoutModule[];
  hideSectionIcon?: boolean;
  isSectionPage?: boolean;
}

const LayoutModuleSwitch: React.FC<Props> = ({
  modules,
  hideSectionIcon,
  isSectionPage,
}) => {
  if (!modules || !modules.length) return null;

  const LayoutModule = (layoutModule: LayoutModule) => {
    switch (layoutModule.type) {
      case 'a': {
        return (
          <LayoutA
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'b': {
        return (
          <LayoutB
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'c': {
        return (
          <LayoutC
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'd': {
        return (
          <LayoutD
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'e': {
        return (
          <LayoutE
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'f': {
        return (
          <LayoutF
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'g': {
        return (
          <LayoutG
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'h': {
        return (
          <LayoutH
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
          />
        );
      }
      case 'i': {
        return (
          <LayoutI
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
          />
        );
      }
      case 'j': {
        return (
          <LayoutJ
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
          />
        );
      }
      case 'k': {
        return (
          <LayoutK
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
          />
        );
      }
      case 'l': {
        return (
          <LayoutL
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
          />
        );
      }
      case 'm': {
        return (
          <LayoutM
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
          />
        );
      }
      case 'n': {
        return (
          <LayoutN
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
          />
        );
      }
      case 'o': {
        return (
          <LayoutO
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
          />
        );
      }
      case 'hero1': {
        return (
          <LayoutHero1
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
          />
        );
      }
      case 'hero2': {
        return (
          <LayoutHero2
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
          />
        );
      }
      case 'hero3': {
        return (
          <LayoutHero3
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
          />
        );
      }
      case 'hero4': {
        return (
          <LayoutHero4
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
          />
        );
      }
      case 'holidayHero': {
        return (
          <LayoutHolidayHero
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
          />
        );
      }
      case 'newsletterWidget': {
        return (
          <NewsletterWidget
            key={layoutModule.id}
            isFullWidth={true}
            widget={layoutModule}
          />
        );
      }
      case 'collections4Stories': {
        return (
          <Collections4Stories
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'columnLayout': {
        return (
          <ColumnLayout key={layoutModule.id} layoutModule={layoutModule} />
        );
      }
      case 'collections4StoriesWidget': {
        return (
          <Collections4StoriesWidget
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'collections6Stories': {
        return (
          <Collections6Stories
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'collections6StoriesWidget': {
        return (
          <Collections6StoriesWidget
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'collections8Stories': {
        return (
          <Collections8Stories
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'collections8StoriesWidget': {
        return (
          <Collections8StoriesWidget
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'collections10Stories': {
        return (
          <Collections10Stories
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'collections10StoriesWidget': {
        return (
          <Collections10StoriesWidget
            key={layoutModule.id}
            layoutModule={layoutModule}
            hideSectionIcon={hideSectionIcon}
            isSectionPage={isSectionPage}
          />
        );
      }
      case 'ad': {
        return <AdLayout key={layoutModule.id} size={layoutModule.size} />;
      }
      default:
        console.warn(
          `Layout Module not found for this layout type. \n`,
          'Module:',
          layoutModule
        );

        return null;
    }
  };

  return (
    <div className="LayoutModuleSwitch">
      {modules &&
        modules.map((layoutModule: LayoutModule) => LayoutModule(layoutModule))}
    </div>
  );
};

export default LayoutModuleSwitch;
