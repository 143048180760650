import React from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';

import { Collections6Stories as ICollections6Stories } from 'sharedTypes';
import CollectionTitle from 'components/CollectionTitle';
import cx from 'classnames';

interface Props {
  layoutModule: ICollections6Stories;
  hideSectionIcon?: boolean;
  isSectionPage?: boolean;
}

const Collections6Stories: React.FC<Props> = ({
  layoutModule,
  hideSectionIcon,
  isSectionPage,
}) => (
  <div className="content-padding-x inner-content-max-width mxauto pb3">
    <div
      className={cx('collections-container collections-6-stories__container', {
        'collection-background-grey': !isSectionPage,
        'collection-background-grey-section': isSectionPage,
      })}
    >
      <CollectionTitle
        collection={layoutModule.collectionsLayoutLibrary.collection}
        slugLink={true}
      />
      <div className="collections-6-stories inner-content-max-width mxauto">
        <div
          className={cx('collections-6-stories__article1-container', {
            'collections-6-stories__article1-container__alternative':
              layoutModule.useAlternative6Collection,
          })}
        >
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article1TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article1}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
        <div
          className={cx('collections-6-stories__article2-container', {
            'lg:none': layoutModule.useAlternative6Collection,
          })}
        >
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article2TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article2}
            hideSectionIcon={hideSectionIcon}
          />
        </div>

        <div
          className={cx(
            'collections-6-stories__article3-container none md:block',
            {
              'lg:none': layoutModule.useAlternative6Collection,
            }
          )}
        >
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article3TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article3}
            hideSectionIcon={hideSectionIcon}
          />
        </div>

        <div className="collections-6-stories__article3-article4-container grid sm:none relative">
          <div className="overflow-hidden flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article3TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article3}
              hideSectionIcon={hideSectionIcon}
              isSplitLayout={true}
            />
          </div>
          <div className="overflow-hidden sm:mt1_5 flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article4TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article4}
              hideSectionIcon={hideSectionIcon}
              isSplitLayout={true}
            />
          </div>
        </div>
        <div className="collections-6-stories__article5-article6-container grid sm:none relative">
          <div className="overflow-hidden flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article5TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article5}
              hideSectionIcon={hideSectionIcon}
              isSplitLayout={true}
            />
          </div>
          <div className="overflow-hidden sm:mt1_5 flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article6TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article6}
              hideSectionIcon={hideSectionIcon}
              isSplitLayout={true}
            />
          </div>
        </div>

        <div className="collections-6-stories__article3-article4-article5-article6-container none sm:grid md:none relative">
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article3TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article3}
            hideSectionIcon={hideSectionIcon}
          />
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article5TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article5}
            hideSectionIcon={hideSectionIcon}
          />
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article4TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article4}
            hideSectionIcon={hideSectionIcon}
          />
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article6TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article6}
            hideSectionIcon={hideSectionIcon}
          />
        </div>

        <div
          className={cx(
            'collections-6-stories__article4-article5-article6-container none md:grid',
            {
              'flex-col lg:flex ': !layoutModule.useAlternative6Collection,
              'lg:none': layoutModule.useAlternative6Collection,
            }
          )}
        >
          <div className="flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article4TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article4}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className="lg:mt1_5 flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article5TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article5}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className="lg:mt1_5 flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article6TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article6}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
        </div>

        <div
          className={cx(
            'collections-6-stories__article2-article3-article4-article5-article6-container none',
            {
              'lg:grid lg:flex ': layoutModule.useAlternative6Collection,
            }
          )}
        >
          <div className="flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article2TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article2}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className="lg:mt1_5 flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article3TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article3}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className="flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article4TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article4}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className="lg:mt1_5 flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article5TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article5}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className="lg:mt1_5 flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article6TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article6}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Collections6Stories;
