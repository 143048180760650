import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import { Button } from 'components/base';
import Language from 'constants/Language';
import { WidgetEvent } from 'sharedTypes';
import { curlyQuotes } from 'utils/text';

interface Props {
  className?: string;
  widget: WidgetEvent;
  isSectionPage?: boolean;
}

const EventWidget: React.FC<Props> = ({ className, widget, isSectionPage }) => {
  return (
    <div
      className={cx(
        'EventWidget widget-container-black bg-color-black p3 flex flex-col items-center text-center',
        className,
        {
          'color-white': !isSectionPage,
          'color-beige': isSectionPage,
        }
      )}
    >
      <p className="graebenbach text-section-header-sm font-700">
        {curlyQuotes(get(widget, 'title', ''))}
      </p>
      {widget.subtitle && (
        <p className="pt_5 pb3_5 text-section-body-xs graebenbach font-400">
          {curlyQuotes(get(widget, 'subtitle', ''))}
        </p>
      )}
      <Button
        ariaLabel={Language.t('Widgets.EventWidget.buttonAriaLabel', {
          eventTitle: get(widget, 'title', ''),
        })}
        to={get(widget, 'link', '')}
        variant="no-style"
        className="graebenbach text-section-details-xs font-400 uppercase color-gray"
        wrap={true}
      >
        <p className="underline text-global-details-xxs pt1">
          {curlyQuotes(get(widget, 'linkText', ''))}
        </p>
      </Button>
    </div>
  );
};

export default EventWidget;
