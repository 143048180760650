import React from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';

import { Collections6StoriesWidget as ICollections6StoriesWidget } from 'sharedTypes';
import CollectionTitle from 'components/CollectionTitle';
import WidgetSwitch from 'components/WidgetSwitch';
import cx from 'classnames';
import hashify from 'object-hash';

interface Props {
  layoutModule: ICollections6StoriesWidget;
  hideSectionIcon?: boolean;
  isSectionPage?: boolean;
}

const Collections6StoriesWidget: React.FC<Props> = ({
  layoutModule,
  hideSectionIcon,
  isSectionPage,
}) => (
  <div
    className={
      'collections-widget-container content-padding-x inner-content-max-width mxauto pb3'
    }
  >
    <div
      className={cx(
        'collections-container collections-widget-container__col-left collections-6-stories-widget__container',
        {
          'collection-background-grey': !isSectionPage,
          'collection-background-grey-section': isSectionPage,
        }
      )}
    >
      <CollectionTitle
        collection={layoutModule.collectionsLayoutLibrary.collection}
        slugLink={true}
      />
      <div className="collections-6-stories-widget inner-content-max-width mxauto">
        <div className="collections-6-stories-widget__article2-container">
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article2TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article2}
            hideSectionIcon={hideSectionIcon}
          />
        </div>

        <div className="collections-6-stories-widget__article3-container none lg:block">
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article3TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article3}
            hideSectionIcon={hideSectionIcon}
          />
        </div>

        <div className="collections-6-stories-widget__article3-article4-container grid sm:none relative">
          <div className="overflow-hidden flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article3TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article3}
              hideSectionIcon={hideSectionIcon}
              isSplitLayout={true}
            />
          </div>
          <div className="overflow-hidden sm:mt1_5 flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article4TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article4}
              hideSectionIcon={hideSectionIcon}
              isSplitLayout={true}
            />
          </div>
        </div>
        <div className="collections-6-stories-widget__article5-article6-container grid sm:none relative">
          <div className="overflow-hidden flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article5TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article5}
              hideSectionIcon={hideSectionIcon}
              isSplitLayout={true}
            />
          </div>
          <div className="overflow-hidden sm:mt1_5 flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article6TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article6}
              hideSectionIcon={hideSectionIcon}
              isSplitLayout={true}
            />
          </div>
        </div>

        <div className="collections-6-stories-widget__article3-article4-article5-article6-container none sm:grid lg:none relative">
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article3TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article3}
            hideSectionIcon={hideSectionIcon}
          />
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article5TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article5}
            hideSectionIcon={hideSectionIcon}
          />
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article4TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article4}
            hideSectionIcon={hideSectionIcon}
          />
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article6TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article6}
            hideSectionIcon={hideSectionIcon}
          />
        </div>

        <div className="collections-6-stories-widget__article4-article5-article6-container none lg:flex flex-col">
          <div className="flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article4TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article4}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className="lg:mt1_5 flex-grow">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article5TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article5}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
        </div>
        <div className="collections-6-stories-widget__article6-container">
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article6TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article6}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
      </div>
      <div className="collections-6-stories-widget__article1-container">
        <StoryTeaserSwitch
          teaser={layoutModule.collectionsLayoutLibrary.article1TeaserType}
          article={layoutModule.collectionsLayoutLibrary.article1}
          hideSectionIcon={hideSectionIcon}
        />
      </div>
    </div>
    <div className="collections-widget-container__col-right border-bottom-black">
      {layoutModule.collectionsLayoutLibrary.widgets && (
        <div className="collections-layout-widget-container layout-sticky-widget">
          {layoutModule.collectionsLayoutLibrary.widgets.map((widget) => {
            return (
              <WidgetSwitch
                key={hashify(widget)}
                widget={widget}
                isSectionPage={isSectionPage}
                isNarrow={true}
              />
            );
          })}
        </div>
      )}
    </div>
  </div>
);

export default Collections6StoriesWidget;
