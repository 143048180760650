import React from 'react';
import cx from 'classnames';
import { BlockContent } from 'components/BlockContent';
import get from 'lodash/get';
import { WidgetText } from 'sharedTypes';
import { curlyQuotes } from 'utils/text';

interface Props {
  className?: string;
  widget: WidgetText;
}

const TextWidget: React.FC<Props> = ({ className, widget }) => {
  const title = get(widget, 'title', '');
  const attribution = get(widget, 'attribution', '');

  return (
    <div
      className={cx(
        'TextWidget color-red widget-container-red flex flex-col items-center',
        {
          className,
          pt1: title,
          pt0: !title,
        }
      )}
    >
      {title && (
        <p className="w100 pb_5 mb1_5 dotted-border-red uppercase text-center itc-cushing text-section-title-xs font-800">
          {curlyQuotes(title)}
        </p>
      )}
      <div
        className={cx(
          'TextWidget__text mb_5 widget-portable-text graebenbach font-400 text-section-body-xs flex flex-row flex-wrap',
          {
            'TextWidget__text--quote': attribution,
          }
        )}
      >
        <BlockContent blocks={widget.text} />
      </div>
      {attribution && (
        <p className="mb_5 mt1 graebenbach text-left font-700 text-section-body-xxs">
          &mdash;{curlyQuotes(attribution)}
        </p>
      )}
    </div>
  );
};

export default TextWidget;
