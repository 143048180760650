import React from 'react';

import { BlockContent } from 'components/BlockContent';
import cx from 'classnames';
import { Button } from 'components/base';

import { WidgetDownloadApp } from 'sharedTypes';
import get from 'lodash/get';
import { curlyQuotes } from 'utils/text';

interface Props {
  className?: string;
  widget: WidgetDownloadApp;
}

const DownloadAppWidget = ({ className, widget }: Props) => {
  const title = get(widget, 'title', '');
  const description = get(widget, 'description', '');
  const buttons = get(widget, 'buttons', []);

  return (
    <div
      className={cx('DownloadAppWidget', {
        className,
      })}
    >
      {title && (
        <h4 className="DownloadAppWidget__title w100 pb1_5 dotted-border-off-white uppercase itc-cushing text-section-title-xs font-700">
          {curlyQuotes(title)}
        </h4>
      )}
      {description && (
        <p className="DownloadAppWidget__description pt1_5 pb_5 graebenbach font-400 text-section-title-xs">
          {description}
        </p>
      )}
      {buttons.map((button, index) => {
        return (
          <Button
            key={index}
            ariaLabel={button.buttonText}
            to={button.buttonUrl}
            className={cx('DownloadAppWidget__button items-center events-all')}
          >
            <span
              className={cx(
                'DownloadAppWidget__button-text graebenbach font-400'
              )}
            >
              {button.buttonText}
            </span>
          </Button>
        );
      })}
    </div>
  );
};

export default DownloadAppWidget;
