import React from 'react';
import cx from 'classnames';
import generateArticleUrl from 'utils/generateArticleUrl';
import generateTagUrl from 'utils/generateTagUrl';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import get from 'lodash/get';
import AuthorButtons from 'components/AuthorButtons';
import SectionButton from 'components/SectionButton';
import { Button, Img } from 'components/base';
import Language from 'constants/Language';
import { ArticleLink, WidgetTag } from 'sharedTypes';
import { Status } from 'types';
import { curlyQuotes } from 'utils/text';
import ApiClient from 'lib/ApiClient';
import { useApiRequest } from 'utils/useApiRequest';

interface TagWidgetProps {
  widget: WidgetTag;
  className: string;
}

/**
 * NEXT_TODO:
 *
 * Currently, this component loads the data only in the browser.
 * Either:
 *  - Fetch & paginate the data from the /index route. This will require:
 *    - Expanding the article references in the Widget groq
 *  - Just let the client fetch this data. The homepage recieves a lot
 *    of traffic, making Sanity CDN requests may not be acceptable.
 *
 */

export const TagWidget: React.FC<TagWidgetProps> = (props) => {
  const {
    className,
    widget: { tag, variant },
  } = props;
  const request = useApiRequest(ApiClient.fetchArticlesByTag, tag.slug);

  if (request.status === Status.PENDING) {
    return null;
  }

  const articles = request?.data?.items || [];
  if (articles.length === 0) return null;
  const tagTitle = get(tag, 'title', '');

  const isOneColumn = variant === 'small' || variant === 'medium';
  const isTwoColumn = variant === 'large';
  const featuredArticle = articles[0];
  const articlesWithoutFeaturedArticle = articles.slice(1);

  return (
    <div className={cx('TagWidget color-black flex flex-col', className)}>
      <div className="TagWidget__header-container flex flex-row items-center justify-between graebenbach md:py_5 w100">
        <div className="TagWidget__tag-title font-700 text-section-title-md">
          {Language.t('Widgets.TagWidget.tagTitle', {
            title: tagTitle,
          })}
        </div>
        <div className="TagWidget__link font-400 flex items-center md:mr0 mr_5">
          <Button
            ariaLabel={Language.t('Widgets.TagWidget.moreAriaLabel', {
              tag: tagTitle,
            })}
            to={generateTagUrl(tag)}
            className="bg-color-transparent p0 move-image-right-on-hover my1 color-black text-section-title-xxs font-400 text-decoration-none text-center"
          >
            <div className="md:inline-flex none">
              {variant === 'small' && Language.t('Widgets.TagWidget.more')}
              {variant === 'medium' &&
                Language.t('Widgets.TagWidget.moreTag', {
                  tag: tagTitle,
                })}
              {variant === 'large' &&
                Language.t('Widgets.TagWidget.moreTagStories', {
                  tag: tagTitle,
                })}
            </div>
            <div className="md:none inline-flex">
              {Language.t('Widgets.TagWidget.seeAll')}
            </div>
            <div className="inline-block ml_25 button-move-arrow graebenbach text-article-details-xs color-black">
              &rarr;&#xFE0E;
            </div>
          </Button>
        </div>
      </div>
      <div
        className={cx(
          'TagWidget__articles-container flex flex-row pt1 pb1_5 sm:pb0 overflow-auto',
          {
            'md:flex-col': isOneColumn,
          }
        )}
      >
        {featuredArticle && (
          <div
            className={cx(
              `TagWidget--${variant}__featured-article pb3 color-black`,
              {
                'col-12 pt_75 pr1': isOneColumn,
                'col-12 md:col-6 dotted-border-right-black pr1 pt_75 md:pt0':
                  isTwoColumn,
                'text-left md:text-center': variant === 'small',
              }
            )}
          >
            {featuredArticle.featuredImage &&
              featuredArticle.featuredImage.src && (
                <Button
                  to={generateArticleUrl(featuredArticle)}
                  ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                    title: get(featuredArticle, 'title', ''),
                  })}
                  className="w100"
                  containerClassName="w100"
                  wrap={true}
                >
                  <div className="relative w100 radius-xs pb1 overflow-hidden md:block none">
                    <Img
                      className="grayscale-img w100 radius-xs"
                      alt={
                        featuredArticle.featuredImage.alt ||
                        featuredArticle.featuredImage.caption ||
                        ''
                      }
                      src={sanityImgUtil(featuredArticle.featuredImage, 900)}
                      srcSet={sanityImgSrcSetUtil(
                        featuredArticle.featuredImage,
                        768,
                        360,
                        900
                      )}
                      sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 360, 900px"
                      dimensions={
                        featuredArticle.featuredImage.metadata?.dimensions
                      }
                      crop={featuredArticle.featuredImage.crop}
                    />
                    <Img
                      className="color-img absolute t0 r0 l0 w100 radius-xs"
                      alt={
                        featuredArticle.featuredImage.alt ||
                        featuredArticle.featuredImage.caption ||
                        ''
                      }
                      src={sanityImgUtil(featuredArticle.featuredImage, 900)}
                      dimensions={
                        featuredArticle.featuredImage.metadata?.dimensions
                      }
                      srcSet={sanityImgSrcSetUtil(
                        featuredArticle.featuredImage,
                        768,
                        360,
                        900
                      )}
                      sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 360, 900px"
                    />
                  </div>
                </Button>
              )}
            {get(featuredArticle, 'section.title') && (
              <SectionButton
                className="mb1_75 md:mb1_5 md:block none"
                section={featuredArticle.section}
              />
            )}
            <Button
              to={generateArticleUrl(featuredArticle)}
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: get(featuredArticle, 'title', ''),
              })}
              wrap={true}
            >
              <p className="TagWidget__article-title md:mb_75 md:mb0 itc-cushing text-section-title-md font-600 color-black">
                {curlyQuotes(get(featuredArticle, 'title', ''))}
              </p>
            </Button>
            <Button
              to={generateArticleUrl(featuredArticle)}
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: get(featuredArticle, 'title', ''),
              })}
              wrap={true}
            >
              <p className="TagWidget__brief text-section-details-sm md:mb1 mb_25 color-gray-darker graebenbach font-400">
                {curlyQuotes(
                  get(featuredArticle, 'brief') ??
                    get(featuredArticle, 'dek', '')
                )}
              </p>
            </Button>
            <span className="text-section-details-xs mb3 md:mb4_5 itc-cushing font-800 uppercase">
              <AuthorButtons authors={get(featuredArticle, 'authors', [])} />
            </span>
          </div>
        )}
        <div
          className={cx('flex flex-row col-12 md:flex-col', {
            'md:col-6 md:ml1': isTwoColumn,
          })}
        >
          {articlesWithoutFeaturedArticle &&
            articlesWithoutFeaturedArticle.map((article: ArticleLink) => {
              const articleTitle = get(article, 'title', '');

              return (
                <div
                  key={`${tagTitle}-${articleTitle}`}
                  className={cx(
                    `TagWidget--${variant}__article pb1 md:pb3 pt_75 md:pt1_5 md:pl0 px1 color-black`,
                    {
                      'text-left md:text-center': variant === 'small',
                      'text-left md:mr_25':
                        variant === 'large' || variant === 'medium',
                      'col-12': isTwoColumn,
                    }
                  )}
                >
                  <SectionButton
                    className="mb1_75 md:mb1_5 md:block none"
                    section={article.section}
                  />
                  <Button
                    key={articleTitle}
                    to={generateArticleUrl(article)}
                    ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                      title: articleTitle,
                    })}
                    wrap={true}
                  >
                    <span
                      className={cx(
                        'TagWidget__article-title md:mb_75 md:mb0 itc-cushing text-section-title-md font-600 text-left color-black',
                        {
                          'md:text-center': variant === 'small',
                        }
                      )}
                    >
                      {curlyQuotes(articleTitle)}
                    </span>
                  </Button>
                  <Button
                    key={articleTitle}
                    to={generateArticleUrl(article)}
                    ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                      title: articleTitle,
                    })}
                    wrap={true}
                  >
                    <p
                      className={cx(
                        'TagWidget__brief text-section-details-sm md:mb1 mb_25 color-gray-darker graebenbach font-400 text-left',
                        {
                          'md:text-center': variant === 'small',
                        }
                      )}
                    >
                      {curlyQuotes(
                        get(article, 'brief') ?? get(article, 'dek', '')
                      )}
                    </p>
                  </Button>
                  <span className="text-section-details-xs mb3 md:mb4_5 itc-cushing font-800 uppercase">
                    <AuthorButtons
                      authors={get(featuredArticle, 'authors', [])}
                    />
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TagWidget;
