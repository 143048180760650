import {
  parseISO,
  differenceInMonths,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  subMonths,
  subDays,
  subHours,
  subMinutes,
} from 'date-fns';

import Language from 'constants/Language';

const generateCountdownInWords = (futureDate: string): string => {
  let parsedFutureDate = parseISO(futureDate);
  const countdownInWords = [];
  const now = new Date();

  const months = differenceInMonths(parsedFutureDate, now);
  if (months === 1) {
    countdownInWords.push(`${months} ${Language.t('Global.month')}`);
    parsedFutureDate = subMonths(parsedFutureDate, months);
  }

  if (months > 1) {
    countdownInWords.push(`${months} ${Language.t('Global.months')}`);
    parsedFutureDate = subMonths(parsedFutureDate, months);
  }

  const days = differenceInDays(parsedFutureDate, now);
  parsedFutureDate = subDays(parsedFutureDate, days);

  if (days === 1) {
    countdownInWords.push(`${days} ${Language.t('Global.day')}`);
  }

  if (days > 1) {
    countdownInWords.push(`${days} ${Language.t('Global.days')}`);
  }

  const hours = differenceInHours(parsedFutureDate, now);
  parsedFutureDate = subHours(parsedFutureDate, hours);

  if (hours === 1) {
    countdownInWords.push(`${hours} ${Language.t('Global.hours')}`);
  }

  if (hours > 1) {
    countdownInWords.push(`${hours} ${Language.t('Global.hours')}`);
  }

  const minutes = differenceInMinutes(parsedFutureDate, now);
  parsedFutureDate = subMinutes(parsedFutureDate, minutes);

  if (minutes === 1) {
    countdownInWords.push(`${minutes} ${Language.t('Global.minute')}`);
  }

  if (minutes > 1) {
    countdownInWords.push(`${minutes} ${Language.t('Global.minutes')}`);
  }

  return countdownInWords.join(', ');
};

export default generateCountdownInWords;
