import React from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';

import { LayoutM as ILayoutM } from 'sharedTypes';

interface Props {
  layoutModule: ILayoutM;
  hideSectionIcon?: boolean;
}

const LayoutM: React.FC<Props> = ({ layoutModule, hideSectionIcon }) => {
  const { alignment } = layoutModule;

  return (
    <div className="LayoutM content-padding-x sm:pt1_5 inner-content-max-width mxauto">
      {alignment === 'right-aligned' ? (
        <>
          <div className={`LayoutM__article1-container layout-story-container`}>
            <StoryTeaserSwitch
              teaser={layoutModule.article2TeaserType}
              article={layoutModule.article2}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className={`LayoutM__article2-container layout-story-container`}>
            <StoryTeaserSwitch
              teaser={layoutModule.article1TeaserType}
              article={layoutModule.article1}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
        </>
      ) : (
        <>
          <div className={`LayoutM__article1-container layout-story-container`}>
            <StoryTeaserSwitch
              teaser={layoutModule.article1TeaserType}
              article={layoutModule.article1}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className={`LayoutM__article2-container layout-story-container`}>
            <StoryTeaserSwitch
              teaser={layoutModule.article2TeaserType}
              article={layoutModule.article2}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default LayoutM;
