import React, { useEffect } from 'react';
import cx from 'classnames';
import Language from 'constants/Language';
import { Img } from 'components/base';
import { fetchShabbatInfo, ShabbatInfoResponse } from './shabbatRequests';
import { WidgetUpcomingShabbat } from 'sharedTypes';

interface Props {
  className?: string;
  widget: WidgetUpcomingShabbat;
}

const UpcomingShabbatWidget: React.FC<Props> = (props) => {
  const { className, widget } = props;
  const [data, setData] = React.useState<ShabbatInfoResponse | null>(null);

  useEffect(() => {
    fetchShabbatInfo().then((data) => setData(data));
  }, []);

  const { date, time, error } = data || {};
  const { title } = widget;

  const classes: string = cx(
    `UpcomingShabbatWidget widget-container-red sm:w100 color-red uppercase flex flex-col items-center`,
    className
  );

  return (
    <div className={classes}>
      <p className="w100 pb_5 dotted-border-red text-center itc-cushing text-section-title-xs font-800">
        {title}
      </p>
      <Img
        className="UpcomingShabbatWidget__icon w100 mt2 mb1"
        src="/assets/images/sundown-icon.svg"
        alt={Language.t('Widgets.UpcomingShabbatWidget.sundownIconAlt')}
      />
      {error ? (
        <p className="mt_5 mb_5 text-center graebenbach text-section-details-xs font-400">
          {error}
        </p>
      ) : (
        <>
          {date && (
            <p className="mt_5 mb_5 text-center graebenbach text-section-details-xs font-400">
              {date}
            </p>
          )}
          {time && (
            <p className="mt_25 mb_5 text-center itc-cushing text-section-title-sm font-800">
              {Language.t('Widgets.UpcomingShabbatWidget.sundown', {
                time: time,
              })}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(UpcomingShabbatWidget);
