import React from 'react';

import { BlockContent } from 'components/BlockContent';
import cx from 'classnames';
import { Button } from 'components/base';

import { WidgetDonate } from 'sharedTypes';
import get from 'lodash/get';
import { curlyQuotes } from 'utils/text';

import { clickTrigger } from 'lib/GTM';

interface Props {
  className?: string;
  widget: WidgetDonate;
}

const DONATION_PAGE_URL = 'https://tabletmag.networkforgood.com/';

const DonationWidget = ({ className, widget }: Props) => {
  const title = get(widget, 'title', '');
  const description = get(widget, 'description', '');
  const ctaLink = get(widget, 'ctaLink', '');
  const ctaText = get(widget, 'ctaText', '');

  const handleDonationButtonClick = (value: string) => {
    clickTrigger({
      event: 'Donation Button Click',
      event_label: `value: ${value}`,
      event_category: 'donation_popup_color_design_summer_2020',
      event_value: value,
    });
  };

  return (
    <div className="DonationWidget">
      <div
        className={cx(
          'DonationWidget__container color-red widget-container-red flex flex-col items-center',
          {
            className,
          }
        )}
      >
        {title && (
          <p className="w100 pb_5 dotted-border-red uppercase text-center itc-cushing text-section-title-xs font-800">
            {curlyQuotes(title)}
          </p>
        )}
        <div
          className={cx(
            'DonationWidget__description widget-portable-text graebenbach font-400 text-section-body-xs flex flex-row flex-wrap'
          )}
        >
          <div>
            <BlockContent blocks={description} />
          </div>
        </div>

        <Button
          to={ctaLink ?? DONATION_PAGE_URL}
          className={cx(
            'DonationWidget__support-button font-700 graebenbach bg-color-red color-white'
          )}
          ariaLabel={ctaText}
          containerClassName="DonationWidget__support-button-container"
          onClick={() => handleDonationButtonClick('Open Donation Page')}
        >
          <span>{ctaText}</span>
        </Button>
      </div>
    </div>
  );
};

export default DonationWidget;
