import React from 'react';
import cx from 'classnames';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import getTeaserLink from 'utils/getTeaserLink';
import { curlyQuotes } from 'utils/text';

import { Button, Img } from 'components/base';
import SectionButton from 'components/SectionButton';
import AuthorButtons from 'components/AuthorButtons';

import Language from 'constants/Language';

import { LayoutHero3 as ILayoutHero3 } from 'sharedTypes';
import get from 'lodash/get';

interface Props {
  layoutModule: ILayoutHero3;
  hideSectionIcon?: boolean;
}

const LayoutHero3: React.FC<Props> = ({ layoutModule, hideSectionIcon }) => {
  const { article, showIntroText } = layoutModule;
  const image = article.featuredImage;
  const heroImage = article.heroImage;
  const mobileHeroImage = article.mobileHeroImage;
  const useFeatureArticleHeroImage =
    article._type === 'featureArticlePage' && article.useArticleHeaderHero;
  const isFiction = get(article, 'isFiction', false);
  const hasSection = 'section' in article && article.section !== undefined;
  const isFeatureArticle =
    get(article, '_type', 'featureArticlePage') === 'featureArticlePage';

  return (
    <div className="LayoutHero3 content-padding-x sm:mt1_5 inner-content-max-width mxauto">
      <div
        className={cx(
          'LayoutHero3__container teaser-widget-container layout-widget-container color-black',
          {
            'fiction-view fiction-view--more-padded': isFiction,
          }
        )}
      >
        <div className="flex flex-col md:flex-row md:items-center">
          {/* Show featured image if it's not a feature article */}
          {!useFeatureArticleHeroImage && image && image.src && (
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: article.title,
              })}
              to={getTeaserLink(article)}
              className={cx('w100 h100', {
                'fiction-teaser-img': isFiction,
              })}
              containerClassName="lg:h100 col-12 md:col-6 lg:mr2_25"
              wrap={true}
              forceInternalLink={isFeatureArticle}
            >
              <div className="w100 h100 relative radius-xs overflow-hidden">
                <Img
                  className="LayoutHero3__image grayscale-img w100 h100 fit-cover radius-xs"
                  alt={image.alt || image.caption || ''}
                  sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
                  src={sanityImgUtil(image, 1920)}
                  srcSet={sanityImgSrcSetUtil(image, 768, 1080, 1920)}
                  dimensions={image.metadata && image.metadata.dimensions}
                  crop={image.crop}
                />
                <Img
                  className="LayoutHero3__image color-img absolute t0 b0 r0 l0 w100 fit-cover radius-xs"
                  alt={image.alt || image.caption || ''}
                  sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
                  src={sanityImgUtil(image, 1920)}
                  dimensions={image.metadata && image.metadata.dimensions}
                  srcSet={sanityImgSrcSetUtil(image, 768, 1080, 1920)}
                />
              </div>
            </Button>
          )}

          {/* Desktop Feature Hero Image */}
          {useFeatureArticleHeroImage && heroImage && heroImage.src && (
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: article.title,
              })}
              to={getTeaserLink(article)}
              className={cx('w100 h100', {
                'fiction-teaser-img': isFiction,
                'none md:block': mobileHeroImage?.src,
                block: !mobileHeroImage?.src,
              })}
              containerClassName="lg:h100 col-12 md:col-6 lg:mr2_25"
              wrap={true}
              forceInternalLink={isFeatureArticle}
            >
              <div className="w100 h100 relative radius-xs overflow-hidden">
                <Img
                  className="LayoutHero3__image grayscale-img w100 h100 fit-cover radius-xs"
                  alt={heroImage.alt || heroImage.caption || ''}
                  sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
                  src={sanityImgUtil(heroImage, 1920)}
                  srcSet={sanityImgSrcSetUtil(heroImage, 768, 1080, 1920)}
                  dimensions={
                    heroImage.metadata && heroImage.metadata.dimensions
                  }
                  crop={heroImage.crop}
                />
                <Img
                  className="LayoutHero3__image color-img absolute t0 b0 r0 l0 w100 fit-cover radius-xs"
                  alt={heroImage.alt || heroImage.caption || ''}
                  sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
                  src={sanityImgUtil(heroImage, 1920)}
                  dimensions={
                    heroImage.metadata && heroImage.metadata.dimensions
                  }
                  srcSet={sanityImgSrcSetUtil(heroImage, 768, 1080, 1920)}
                />
              </div>
            </Button>
          )}

          {/* Mobile Feature Hero Image */}
          {useFeatureArticleHeroImage &&
            mobileHeroImage &&
            mobileHeroImage.src && (
              <Button
                ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                  title: article.title,
                })}
                to={getTeaserLink(article)}
                className={cx('w100 h100 block md:none', {
                  'fiction-teaser-img': isFiction,
                })}
                containerClassName="lg:h100 col-12 md:col-6 lg:mr2_25"
                wrap={true}
                forceInternalLink={isFeatureArticle}
              >
                <div className="w100 h100 relative radius-xs overflow-hidden">
                  <Img
                    className="LayoutHero3__image grayscale-img w100 h100 fit-cover radius-xs"
                    alt={mobileHeroImage.alt || mobileHeroImage.caption || ''}
                    sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
                    src={sanityImgUtil(mobileHeroImage, 1920)}
                    srcSet={sanityImgSrcSetUtil(
                      mobileHeroImage,
                      768,
                      1080,
                      1920
                    )}
                    dimensions={
                      mobileHeroImage.metadata &&
                      mobileHeroImage.metadata.dimensions
                    }
                    crop={mobileHeroImage.crop}
                  />
                  <Img
                    className="LayoutHero3__image color-img absolute t0 b0 r0 l0 w100 fit-cover radius-xs"
                    alt={mobileHeroImage.alt || mobileHeroImage.caption || ''}
                    sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px, 1920px"
                    src={sanityImgUtil(mobileHeroImage, 1920)}
                    dimensions={
                      mobileHeroImage.metadata &&
                      mobileHeroImage.metadata.dimensions
                    }
                    srcSet={sanityImgSrcSetUtil(
                      mobileHeroImage,
                      768,
                      1080,
                      1920
                    )}
                  />
                </div>
              </Button>
            )}

          <div className="col-12 md:col-6 flex flex-col items-center">
            <div className="flex items-center">
              <div className="LayoutHero3__title-container relative flex flex-col items-center my1 lg:my2_5">
                {!useFeatureArticleHeroImage &&
                  hasSection &&
                  article.section && (
                    <SectionButton
                      className="mb1 lg:mb2_25"
                      section={article.section}
                      hideIcon={hideSectionIcon}
                      isFictionTeaser={isFiction}
                    />
                  )}
                {!useFeatureArticleHeroImage && (
                  <>
                    <Button
                      ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                        title: article.title,
                      })}
                      to={getTeaserLink(article)}
                      containerClassName="mb1 text-center color-black"
                      wrap={true}
                      forceInternalLink={isFeatureArticle}
                    >
                      <span className="linked-border-bottom-lg-on-hover-red text-section-header-xl itc-cushing font-300">
                        {curlyQuotes(article.title)}
                      </span>
                    </Button>
                    <Button
                      ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                        title: article.title,
                      })}
                      to={getTeaserLink(article)}
                      containerClassName="mb1 text-center"
                      wrap={true}
                      forceInternalLink={isFeatureArticle}
                    >
                      <span className="text-section-details-sm graebenbach font-400 color-gray-darker">
                        {curlyQuotes(article.dek)}
                      </span>
                    </Button>
                  </>
                )}
                {!useFeatureArticleHeroImage && !!article.authors?.length && (
                  <AuthorButtons
                    className="text-center"
                    authors={article.authors}
                  />
                )}
              </div>
            </div>

            {!!showIntroText && (
              <Button
                ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                  title: article.title,
                })}
                to={getTeaserLink(article)}
                containerClassName="mt1_5 lg:mt_5 md:none lg:flex"
                wrap={true}
                forceInternalLink={isFeatureArticle}
              >
                <div
                  className={cx(
                    'LayoutHero3__excerpt-container relative color-black pt1 lg:pt2_25',
                    {
                      'fiction-teaser-border': isFiction,
                    }
                  )}
                >
                  <p className="text-layout-dropcaps text-section-body-sm graebenbach font-400">
                    {curlyQuotes(article.excerpt)}
                  </p>
                  <div className="pt1_5">
                    <span className="LayoutHero3__continue-reading relative color-black graebenbach text-section-body-sm font-400">
                      {Language.t('Global.continueReading')} &rarr;&#xFE0E;
                    </span>
                  </div>
                </div>
              </Button>
            )}
          </div>
        </div>

        {!!showIntroText && (
          <Button
            ariaLabel={Language.t('Global.articleButtonAriaLabel', {
              title: article.title,
            })}
            to={getTeaserLink(article)}
            containerClassName="mt1_5 lg:mt_5 none md:flex lg:none"
            wrap={true}
            forceInternalLink={isFeatureArticle}
          >
            <div className="LayoutHero3__excerpt-container relative color-black pt1 lg:pt2_25">
              <p className="text-layout-dropcaps text-section-body-sm graebenbach font-400">
                {curlyQuotes(article.excerpt)}
              </p>
              <div className="pt1_5">
                <span className="LayoutHero3__continue-reading relative color-black graebenbach text-section-body-sm font-400">
                  {Language.t('Global.continueReading')} &rarr;&#xFE0E;
                </span>
              </div>
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

export default LayoutHero3;
