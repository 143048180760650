import React from 'react';
import cx from 'classnames';
import hashify from 'object-hash';
import { BlockContent } from 'components/BlockContent';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import get from 'lodash/get';
import { Img } from 'components/base';
import { WidgetList, WidgetListItem } from 'sharedTypes';
import { curlyQuotes } from 'utils/text';

interface Props {
  className?: string;
  widget: WidgetList;
}

const ListWidget: React.FC<Props> = ({ className, widget }) => {
  const title = get(widget, 'title', '');
  const variant = get(widget, 'variant', '');

  const isOrderedList = variant === 'number' || variant === 'fancy-number';
  const isUnorderedList = variant === 'bullet' || variant === 'no-style';

  return (
    <div
      className={cx(
        'ListWidget color-red widget-container-red flex flex-col items-center',
        className
      )}
    >
      {title && (
        <p className="w100 pb_5 dotted-border-red uppercase text-center itc-cushing text-section-title-xs font-800">
          {curlyQuotes(title)}
        </p>
      )}
      {isOrderedList && (
        <ol
          className={cx('ListWidget__items list-style-none w100 mx1', {
            'text-left': variant === 'number',
            'text-center': variant === 'fancy-number',
          })}
        >
          {widget.items &&
            widget.items.map((item: WidgetListItem, index: number) => {
              const text = get(item, 'text', '');
              return (
                <li
                  className={cx(
                    'widget-portable-text graebenbach font-400 text-widget-body-md dotted-border-red py1_5 w100'
                    // {
                    //   flex: variant === 'number',
                    // }
                  )}
                  key={hashify(text)}
                >
                  {variant === 'number' ? (
                    <div className="flex flex-col">
                      {item.image && (
                        <div>
                          <div className="img-container relative w100 radius-sm mb_5 overflow-hidden flex">
                            <Img
                              className="grayscale-img w100 h100 fit-cover radius-sm"
                              alt={item.image.alt || item.image.caption || ''}
                              src={sanityImgUtil(item.image, 900)}
                              srcSet={sanityImgSrcSetUtil(
                                item.image,
                                768,
                                360,
                                900
                              )}
                              sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 360, 900px"
                              dimensions={
                                item.image.metadata &&
                                item.image.metadata.dimensions
                              }
                              crop={item.image.crop}
                            />
                            <Img
                              className="color-img absolute t0 l0 r0 w100 h100 fit-cover radius-sm"
                              alt={item.image.alt || item.image.caption || ''}
                              src={sanityImgUtil(item.image, 900)}
                              srcSet={sanityImgSrcSetUtil(
                                item.image,
                                768,
                                360,
                                900
                              )}
                              dimensions={
                                item.image.metadata &&
                                item.image.metadata.dimensions
                              }
                              sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 360, 900px"
                            />
                          </div>
                          {item.image.rteCredit ? (
                            <div className="ImageCreditLink color-gray text-article-details-xxxs font-400 uppercase graebenbach letter-spacing-md mb1_25 flex justify-end">
                              <BlockContent blocks={item.image.rteCredit} />
                            </div>
                          ) : item.image.credit ? (
                            <span className="flex justify-end color-gray text-article-details-xxxs font-400 uppercase graebenbach letter-spacing-md mb1_25">
                              {curlyQuotes(item.image.credit)}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                      <div className="ListWidget__paragraph">
                        <span>{index + 1}. </span>
                        <BlockContent blocks={text} />
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col">
                      <span className="text-section-title-lg itc-cushing color-red pb_75">
                        {index + 1}
                      </span>
                      {item.image && (
                        <div>
                          <div className="img-container relative w100 radius-sm mb_5 overflow-hidden flex">
                            <Img
                              className="grayscale-img w100"
                              src={sanityImgUtil(item.image, 1080)}
                              srcSet={sanityImgSrcSetUtil(
                                item.image,
                                768,
                                540,
                                1080
                              )}
                              sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 540, 1080px"
                              alt={item.image.alt || item.image.caption || ''}
                              dimensions={
                                item.image.metadata &&
                                item.image.metadata.dimensions
                              }
                              crop={item.image.crop}
                            />
                            <Img
                              className="color-img absolute t0 l0 r0 w100"
                              src={sanityImgUtil(item.image, 1080)}
                              srcSet={sanityImgSrcSetUtil(
                                item.image,
                                768,
                                540,
                                1080
                              )}
                              dimensions={
                                item.image.metadata &&
                                item.image.metadata.dimensions
                              }
                              sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 540, 1080px"
                              alt={item.image.alt || item.image.caption || ''}
                            />
                          </div>
                          {item.image.rteCredit ? (
                            <span className="ImageCreditLink color-gray text-article-details-xxxs font-400 uppercase graebenbach letter-spacing-md mb1_25">
                              <BlockContent blocks={item.image.rteCredit} />
                            </span>
                          ) : item.image.credit ? (
                            <span className="flex justify-end color-gray text-article-details-xxxs font-400 uppercase graebenbach letter-spacing-md mb1_25">
                              {curlyQuotes(item.image.credit)}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                      <div className="ListWidget__paragraph">
                        <BlockContent blocks={text} />
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
        </ol>
      )}
      {isUnorderedList && (
        <ul
          className={cx('ListWidget__items mx1 w100', {
            'ListWidget__items--bullet-list text-left': variant === 'bullet',
            'text-center': variant === 'no-style',
          })}
        >
          {widget.items &&
            widget.items.map((item: WidgetListItem) => {
              const text = get(item, 'text', []);

              return (
                <li
                  key={hashify(item)}
                  className="ListWidget__item py1_5 dotted-border-red"
                >
                  {item.image && (
                    <div className="img-container relative w100 radius-sm mb_75 overflow-hidden flex">
                      <Img
                        className="grayscale-img w100 h100 fit-cover"
                        src={sanityImgUtil(item.image, 1080)}
                        srcSet={sanityImgSrcSetUtil(item.image, 768, 540, 1080)}
                        sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 540, 1080px"
                        alt={item.image.alt || item.image.caption || ''}
                        dimensions={
                          item.image.metadata && item.image.metadata.dimensions
                        }
                        crop={item.image.crop}
                      />
                      <Img
                        className="color-img absolute t0 l0 r0 w100 h100 fit-cover"
                        src={sanityImgUtil(item.image, 1080)}
                        srcSet={sanityImgSrcSetUtil(item.image, 768, 540, 1080)}
                        dimensions={
                          item.image.metadata && item.image.metadata.dimensions
                        }
                        sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 540, 1080px"
                        alt={item.image.alt || item.image.caption || ''}
                      />
                    </div>
                  )}
                  <div
                    className={cx(
                      'ListWidget__unordered-list-item widget-portable-text graebenbach font-400 text-section-body-xs list-style-none',
                      {
                        'flex flex-col justify-center': variant === 'no-style',
                      }
                    )}
                    key={hashify(text)}
                  >
                    <div>
                      <BlockContent blocks={text} />
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};

export default ListWidget;
