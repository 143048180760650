import React from 'react';
import hashify from 'object-hash';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';
import WidgetSwitch from 'components/WidgetSwitch';

import { LayoutB as ILayoutB } from 'sharedTypes';

interface Props {
  layoutModule: ILayoutB;
  hideSectionIcon?: boolean;
  isSectionPage?: boolean;
}

const LayoutB: React.FC<Props> = ({
  layoutModule,
  hideSectionIcon,
  isSectionPage,
}) => {
  const { alignment, widgets } = layoutModule;

  return (
    <div className="LayoutB content-padding-x sm:pt1_5 inner-content-max-width mxauto">
      <div
        className={`LayoutB__article1-container--${alignment} layout-story-container`}
      >
        <StoryTeaserSwitch
          teaser={layoutModule.article1TeaserType}
          article={layoutModule.article1}
          hideSectionIcon={hideSectionIcon}
        />
      </div>
      <div
        className={`LayoutB__article2-container--${alignment} layout-story-container none sm:block`}
      >
        <StoryTeaserSwitch
          teaser={layoutModule.article2TeaserType}
          article={layoutModule.article2}
          hideSectionIcon={hideSectionIcon}
        />
      </div>
      <div
        className={`LayoutB__article3-container--${alignment} layout-story-container none sm:block`}
      >
        <StoryTeaserSwitch
          teaser={layoutModule.article3TeaserType}
          article={layoutModule.article3}
          hideSectionIcon={hideSectionIcon}
        />
      </div>
      <div className="LayoutB__article2-article3-container relative">
        <div className="overflow-hidden non-main-teaser">
          <StoryTeaserSwitch
            teaser={layoutModule.article2TeaserType}
            article={layoutModule.article2}
            hideSectionIcon={hideSectionIcon}
            isSplitLayout={true}
          />
        </div>
        <div className="overflow-hidden non-main-teaser">
          <StoryTeaserSwitch
            teaser={layoutModule.article3TeaserType}
            article={layoutModule.article3}
            hideSectionIcon={hideSectionIcon}
            isSplitLayout={true}
          />
        </div>
      </div>
      <div
        className={`LayoutB__widget-container LayoutB__widget-container--${alignment} layout-widget-container`}
      >
        {widgets && (
          <div className="layout-sticky-widget layout-widgets-container">
            {widgets.map((widget) => {
              return (
                <WidgetSwitch
                  key={hashify(widget)}
                  widget={widget}
                  isSectionPage={isSectionPage}
                  isNarrow={true}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default LayoutB;
