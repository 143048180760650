import React from 'react';
import cx from 'classnames';
import { BlockContent } from 'components/BlockContent';
import get from 'lodash/get';
import { WidgetFact } from 'sharedTypes';
import { curlyQuotes } from 'utils/text';

interface Props {
  className?: string;
  widget: WidgetFact;
}

const FactWidget: React.FC<Props> = ({ className, widget }) => {
  const title = get(widget, 'title', '');

  return (
    <div
      className={cx(
        'FactWidget color-red widget-container-red flex flex-col items-center text-center',
        {
          className,
          pt1: title,
          pt2: !title,
        }
      )}
    >
      {title && (
        <p className="w100 pb_5 dotted-border-red uppercase text-center itc-cushing text-section-title-xs font-800">
          {curlyQuotes(title)}
        </p>
      )}
      <p
        className={cx('itc-cushing text-section-title-xl', {
          mt2: title,
          mt1_5: !title,
        })}
      >
        {curlyQuotes(get(widget, 'fact', ''))}
      </p>
      <div className="widget-portable-text graebenbach color-red pt1 mb_5 font-400 text-section-body-xs">
        <BlockContent blocks={get(widget, 'text', [])} />
      </div>
    </div>
  );
};

export default FactWidget;
