import React from 'react';

import cx from 'classnames';
import { curlyQuotes } from 'utils/text';
import hashify from 'object-hash';

import Language from 'constants/Language';
import { Img } from 'components/base';
import generateArticleUrl from 'utils/generateArticleUrl';
import get from 'lodash/get';

import { WidgetAuthor, ArticleLink, AuthorLink } from 'sharedTypes';
import { RouteMap } from 'constants/RouteMap';
import { Button } from 'components/base';

interface Props {
  className?: string;
  widget: WidgetAuthor;
  isSectionPage?: boolean;
}

const AuthorWidget: React.FC<Props> = ({ className, widget }) => {
  const { variant, articles } = widget;
  return (
    <div
      className={cx(`AuthorWidget variant-${variant}`, className, {
        'widget-container-black color-black': variant === 'black',
        'widget-container-red color-red': variant === 'red',
      })}
    >
      <Img
        className="AuthorWidget__icon mb1_375"
        src={`/assets/images/typewriter-${variant}.png`}
        alt={Language.t('Widgets.AuthorWidget.typewriterImageAlt')}
      />
      <p className="AuthorWidget__title uppercase itc-cushing text-section-body-md letter-spacing-sm font-800 text-center">
        {Language.t('Widgets.AuthorWidget.title')}
      </p>

      <div className="AuthorWidget__items mb1_25">
        {articles &&
          articles.map((article: ArticleLink) => {
            const authors = get(article, 'authors');
            const title = get(article, 'title');

            return (
              <div
                key={article.slug}
                className={cx(`AuthorWidget__item py1 graebenbach`, className, {
                  'dotted-border-black': variant === 'black',
                  'dotted-border-red': variant === 'red',
                })}
              >
                <div className="AuthorWidget__item__author font-700">
                  {authors.map((author: AuthorLink, i) => {
                    const name = `${author.firstName} ${author.lastName}`;
                    return (
                      <span key={hashify(author)}>
                        {i !== 0 ? `, ${name}` : name}
                      </span>
                    );
                  })}
                </div>
                <Button
                  ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                    title: title,
                  })}
                  to={generateArticleUrl(article)}
                  wrap={true}
                  className={cx(`w100 font-700 AuthorWidget__item__title`, {
                    'color-black': variant === 'black',
                    'color-red': variant === 'red',
                  })}
                >
                  {curlyQuotes(title)}
                </Button>
              </div>
            );
          })}
      </div>
      <Button
        to={RouteMap.CONTRIBUTOR.base}
        ariaLabel={Language.t('Widgets.AuthorWidget.buttonAriaLabel')}
        containerClassName="w100"
        className={cx(
          `w100 font-700 AuthorWidget__button graebenbach text-center`,
          {
            'color-black': variant === 'black',
            'color-red': variant === 'red',
          }
        )}
      >
        {Language.t('Widgets.AuthorWidget.seeAll')} &rarr;&#xFE0E;
      </Button>
    </div>
  );
};

export default AuthorWidget;
