import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import { Button } from 'components/base';
import { RouteMap } from 'constants/RouteMap';
import Language from 'constants/Language';
import truncate from 'utils/truncate';
import { curlyQuotes } from 'utils/text';
import { WidgetEncyclopedia } from 'sharedTypes';

interface Props {
  className?: string;
  widget: WidgetEncyclopedia;
}

const EncyclopediaWidget: React.FC<Props> = ({ className, widget }) => {
  const { encyclopediaTerm } = widget;

  return (
    <div
      className={cx(
        'EncyclopediaWidget color-red widget-container-red flex flex-col',
        className
      )}
    >
      <p className="dotted-border-red mb1_5 pb_5 w100 uppercase text-center itc-cushing text-section-title-xs font-800">
        {Language.t('Widgets.EncyclopediaWidget.title')}
      </p>
      <p className="graebenbach text-section-body-xs font-700">
        {curlyQuotes(get(encyclopediaTerm, 'term', ''))}
      </p>
      <div className="graebenbach font-400 text-section-details-xs">
        <span className="uppercase">{`[${encyclopediaTerm.pronunciation}]`}</span>
        <span className="default-letter-spacing italic">
          {' '}
          {get(encyclopediaTerm, 'typeOfWord', '')}
        </span>
      </div>
      <p className="graebenbach font-400 text-section-body-xs">
        {curlyQuotes(truncate(get(encyclopediaTerm, 'definition', ''), 140))}
      </p>
      <div className="flex justify-center">
        <Button
          ariaLabel={Language.t('Global.encyclopediaButtonAriaLabel', {
            term: get(encyclopediaTerm, 'term', ''),
          })}
          to={RouteMap.ENCYCLOPEDIA.path}
          className="move-image-right-on-hover mt2 mb_5 color-red graebenbach font-400 text-decoration-none text-center"
        >
          {Language.t('Global.readMore')}
          <div className="inline-block ml_25 button-move-arrow graebenbach text-article-details-xs color-red">
            &rarr;&#xFE0E;
          </div>
        </Button>
      </div>
    </div>
  );
};

export default EncyclopediaWidget;
