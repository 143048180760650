import React from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';

import { Collections4Stories as ICollections4Stories } from 'sharedTypes';
import CollectionTitle from 'components/CollectionTitle';
import cx from 'classnames';

interface Props {
  layoutModule: ICollections4Stories;
  hideSectionIcon?: boolean;
  isSectionPage?: boolean;
}

const Collections4Stories: React.FC<Props> = ({
  layoutModule,
  hideSectionIcon,
  isSectionPage,
}) => (
  <div className="content-padding-x inner-content-max-width mxauto pb3">
    <div
      className={cx(
        'collections-4-stories collections-container collection-background-grey',
        {
          'collection-background-grey': !isSectionPage,
          'collection-background-grey-section': isSectionPage,
        }
      )}
    >
      <CollectionTitle
        collection={layoutModule.collectionsLayoutLibrary.collection}
        slugLink={true}
      />
      <div className={'collections-container__col-left'}>
        <div className="collections-4-stories__container  inner-content-max-width mxauto">
          <div className="collections-4-stories__container__article1-container layout-story-container">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article1TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article1}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className="collections-4-stories__container__article2-article3-container relative">
            <div className="overflow-hidden non-main-teaser col1">
              <StoryTeaserSwitch
                teaser={
                  layoutModule.collectionsLayoutLibrary.article2TeaserType
                }
                article={layoutModule.collectionsLayoutLibrary.article2}
                hideSectionIcon={hideSectionIcon}
                isSplitLayout={true}
              />
            </div>
            <div className="overflow-hidden non-main-teaser col2">
              <StoryTeaserSwitch
                teaser={
                  layoutModule.collectionsLayoutLibrary.article3TeaserType
                }
                article={layoutModule.collectionsLayoutLibrary.article3}
                hideSectionIcon={hideSectionIcon}
                isSplitLayout={true}
              />
            </div>
          </div>
          <div className="collections-4-stories__container__article2-container layout-story-container none sm:block non-main-teaser">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article2TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article2}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className="collections-4-stories__container__article3-container layout-story-container none sm:block non-main-teaser">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article3TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article3}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className="collections-4-stories__container__article4-container">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article4TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article4}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Collections4Stories;
