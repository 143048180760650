import React from 'react';
import { Link } from 'components/Link';

import cx from 'classnames';
import hashify from 'object-hash';
import generateAuthorUrl from 'utils/generateAuthorUrl';

import Language from 'constants/Language';

import { AuthorLink } from 'sharedTypes';

interface Props {
  className?: string;
  authors?: AuthorLink[];
  publishedDate?: string;
  underline?: boolean;
}

const AuthorDateButtons: React.FC<Props> = ({
  className,
  authors = [],
  publishedDate,
  underline = false,
}) => {
  const authorsLength = authors.length;
  return (
    <div
      className={cx(
        'AuthorDateButtons text-section-details-xs itc-cushing font-800 uppercase',
        className
      )}
    >
      {authorsLength > 0 && (
        <span className="pr_25 byline">{Language.t('Global.byline')}</span>
      )}
      {authors.map((author: AuthorLink, i) => {
        const name = `${author.firstName} ${author.lastName}`;
        const key = `${hashify(authors)}-${hashify(author)}`;
        return (
          <React.Fragment key={key}>
            <Link
              className="AuthorDateButtons__button"
              to={generateAuthorUrl(author)}
            >
              <span
                className={cx(
                  'AuthorDateButtons__button-text line-height-xxs color-black transition-shorter',
                  {
                    'linked-border-bottom-red': underline,
                    pr_25: !(i !== authorsLength - 1 && authorsLength !== 2),
                  }
                )}
              >
                {name}
              </span>
              {i !== authorsLength - 1 && authorsLength !== 2 && (
                <span className="pr_25 color-black">
                  {Language.t('Global.comma')}
                </span>
              )}
            </Link>
            {i !== authorsLength - 1 && i === authorsLength - 2 && (
              <span className="pr_25">{Language.t('Global.and')}</span>
            )}
          </React.Fragment>
        );
      })}
      {publishedDate && (
        <span>{`${
          publishedDate && authorsLength > 0 ? '| ' : ''
        }${publishedDate}`}</span>
      )}
    </div>
  );
};

export default AuthorDateButtons;
