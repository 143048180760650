import React from 'react';

import StoryTeaserSwitch from '../../StoryTeaserSwitch';

import { Collections10Stories as ICollections10Stories } from '../../../sharedTypes';
import CollectionTitle from '../../CollectionTitle';
import cx from 'classnames';

interface Props {
  layoutModule: ICollections10Stories;
  hideSectionIcon?: boolean;
  isSectionPage?: boolean;
}

const Collections10Stories: React.FC<Props> = ({
  layoutModule,
  hideSectionIcon,
  isSectionPage,
}) => {
  return (
    <div className="content-padding-x inner-content-max-width mxauto pb3">
      <div
        className={cx('collections-10-stories', {
          'collection-background-grey': !isSectionPage,
          'collection-background-grey-section': isSectionPage,
        })}
      >
        <div className="collections-container collections-6-stories__container">
          <CollectionTitle
            collection={layoutModule.collectionsLayoutLibrary.collection}
            slugLink={true}
          />
          <div className="collections-6-stories inner-content-max-width mxauto">
            <div className="collections-6-stories__article1-container">
              <StoryTeaserSwitch
                teaser={
                  layoutModule.collectionsLayoutLibrary.article1TeaserType
                }
                article={layoutModule.collectionsLayoutLibrary.article1}
                hideSectionIcon={hideSectionIcon}
              />
            </div>
            <div className="collections-6-stories__article2-container">
              <StoryTeaserSwitch
                teaser={
                  layoutModule.collectionsLayoutLibrary.article2TeaserType
                }
                article={layoutModule.collectionsLayoutLibrary.article2}
                hideSectionIcon={hideSectionIcon}
              />
            </div>

            <div className="collections-6-stories__article3-container none md:block">
              <StoryTeaserSwitch
                teaser={
                  layoutModule.collectionsLayoutLibrary.article3TeaserType
                }
                article={layoutModule.collectionsLayoutLibrary.article3}
                hideSectionIcon={hideSectionIcon}
              />
            </div>

            <div className="collections-6-stories__article3-article4-container grid sm:none relative">
              <div className="overflow-hidden flex-grow">
                <StoryTeaserSwitch
                  teaser={
                    layoutModule.collectionsLayoutLibrary.article3TeaserType
                  }
                  article={layoutModule.collectionsLayoutLibrary.article3}
                  hideSectionIcon={hideSectionIcon}
                  isSplitLayout={true}
                />
              </div>
              <div className="overflow-hidden sm:mt1_5 flex-grow">
                <StoryTeaserSwitch
                  teaser={
                    layoutModule.collectionsLayoutLibrary.article4TeaserType
                  }
                  article={layoutModule.collectionsLayoutLibrary.article4}
                  hideSectionIcon={hideSectionIcon}
                  isSplitLayout={true}
                />
              </div>
            </div>
            <div className="collections-6-stories__article5-article6-container grid sm:none relative">
              <div className="overflow-hidden flex-grow">
                <StoryTeaserSwitch
                  teaser={
                    layoutModule.collectionsLayoutLibrary.article5TeaserType
                  }
                  article={layoutModule.collectionsLayoutLibrary.article5}
                  hideSectionIcon={hideSectionIcon}
                  isSplitLayout={true}
                />
              </div>
              <div className="overflow-hidden sm:mt1_5 flex-grow">
                <StoryTeaserSwitch
                  teaser={
                    layoutModule.collectionsLayoutLibrary.article6TeaserType
                  }
                  article={layoutModule.collectionsLayoutLibrary.article6}
                  hideSectionIcon={hideSectionIcon}
                  isSplitLayout={true}
                />
              </div>
            </div>

            <div className="collections-6-stories__article3-article4-article5-article6-container none sm:grid md:none relative">
              <StoryTeaserSwitch
                teaser={
                  layoutModule.collectionsLayoutLibrary.article3TeaserType
                }
                article={layoutModule.collectionsLayoutLibrary.article3}
                hideSectionIcon={hideSectionIcon}
              />
              <StoryTeaserSwitch
                teaser={
                  layoutModule.collectionsLayoutLibrary.article5TeaserType
                }
                article={layoutModule.collectionsLayoutLibrary.article5}
                hideSectionIcon={hideSectionIcon}
              />
              <StoryTeaserSwitch
                teaser={
                  layoutModule.collectionsLayoutLibrary.article4TeaserType
                }
                article={layoutModule.collectionsLayoutLibrary.article4}
                hideSectionIcon={hideSectionIcon}
              />
              <StoryTeaserSwitch
                teaser={
                  layoutModule.collectionsLayoutLibrary.article6TeaserType
                }
                article={layoutModule.collectionsLayoutLibrary.article6}
                hideSectionIcon={hideSectionIcon}
              />
            </div>

            <div className="collections-6-stories__article4-article5-article6-container none md:grid lg:flex flex-col">
              <div className="flex-grow">
                <StoryTeaserSwitch
                  teaser={
                    layoutModule.collectionsLayoutLibrary.article4TeaserType
                  }
                  article={layoutModule.collectionsLayoutLibrary.article4}
                  hideSectionIcon={hideSectionIcon}
                />
              </div>
              <div className="lg:mt1_5 flex-grow">
                <StoryTeaserSwitch
                  teaser={
                    layoutModule.collectionsLayoutLibrary.article5TeaserType
                  }
                  article={layoutModule.collectionsLayoutLibrary.article5}
                  hideSectionIcon={hideSectionIcon}
                />
              </div>
              <div className="lg:mt1_5 flex-grow">
                <StoryTeaserSwitch
                  teaser={
                    layoutModule.collectionsLayoutLibrary.article6TeaserType
                  }
                  article={layoutModule.collectionsLayoutLibrary.article6}
                  hideSectionIcon={hideSectionIcon}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={'collections-4-stories collections-container'}>
          <div className={'collections-container__col-left'}>
            <div className="collections-4-stories__container  inner-content-max-width mxauto">
              <div className="collections-4-stories__container__article1-container layout-story-container">
                <StoryTeaserSwitch
                  teaser={
                    layoutModule.collectionsLayoutLibrary.article7TeaserType
                  }
                  article={layoutModule.collectionsLayoutLibrary.article7}
                  hideSectionIcon={hideSectionIcon}
                />
              </div>
              <div className="collections-4-stories__container__article2-article3-container relative">
                <div className="overflow-hidden non-main-teaser col1">
                  <StoryTeaserSwitch
                    teaser={
                      layoutModule.collectionsLayoutLibrary.article8TeaserType
                    }
                    article={layoutModule.collectionsLayoutLibrary.article8}
                    hideSectionIcon={hideSectionIcon}
                    isSplitLayout={true}
                  />
                </div>
                <div className="overflow-hidden non-main-teaser col2">
                  <StoryTeaserSwitch
                    teaser={
                      layoutModule.collectionsLayoutLibrary.article9TeaserType
                    }
                    article={layoutModule.collectionsLayoutLibrary.article9}
                    hideSectionIcon={hideSectionIcon}
                    isSplitLayout={true}
                  />
                </div>
              </div>
              <div className="collections-4-stories__container__article2-container layout-story-container sm:block non-main-teaser">
                <StoryTeaserSwitch
                  teaser={
                    layoutModule.collectionsLayoutLibrary.article10TeaserType
                  }
                  article={layoutModule.collectionsLayoutLibrary.article10}
                  hideSectionIcon={hideSectionIcon}
                />
              </div>
              <div className="collections-4-stories__container__article3-container layout-story-container none sm:block non-main-teaser">
                <StoryTeaserSwitch
                  teaser={
                    layoutModule.collectionsLayoutLibrary.article8TeaserType
                  }
                  article={layoutModule.collectionsLayoutLibrary.article8}
                  hideSectionIcon={hideSectionIcon}
                />
              </div>
              <div className="collections-4-stories__container__article4-container none sm:block">
                <StoryTeaserSwitch
                  teaser={
                    layoutModule.collectionsLayoutLibrary.article9TeaserType
                  }
                  article={layoutModule.collectionsLayoutLibrary.article9}
                  hideSectionIcon={hideSectionIcon}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Collections10Stories;
