import React from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';

import { LayoutN as ILayoutN } from 'sharedTypes';

interface Props {
  layoutModule: ILayoutN;
  hideSectionIcon?: boolean;
}

const LayoutN: React.FC<Props> = ({ layoutModule, hideSectionIcon }) => {
  const { alignment } = layoutModule;

  return (
    <div className="LayoutN content-padding-x sm:pt1_5 inner-content-max-width mxauto">
      {alignment === 'right-aligned' ? (
        <>
          <div className="LayoutN__article1-container layout-story-container">
            <StoryTeaserSwitch
              teaser={layoutModule.article2TeaserType}
              article={layoutModule.article2}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className="LayoutN__article2-container layout-story-container">
            <StoryTeaserSwitch
              teaser={layoutModule.article1TeaserType}
              article={layoutModule.article1}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
        </>
      ) : (
        <>
          <div className="LayoutN__article1-container layout-story-container">
            <StoryTeaserSwitch
              teaser={layoutModule.article1TeaserType}
              article={layoutModule.article1}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
          <div className="LayoutN__article2-container layout-story-container">
            <StoryTeaserSwitch
              teaser={layoutModule.article2TeaserType}
              article={layoutModule.article2}
              hideSectionIcon={hideSectionIcon}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default LayoutN;
