import React, { useEffect, useState } from 'react';
import { Button } from 'components/base';
import { WidgetColumnist, ColumnistLayout } from 'sharedTypes';

import { Columnist } from 'components/widgets/ColumnistWidget/Columnist';
import ApiClient from 'lib/ApiClient';

interface ColumnistWidgetProps {
  className?: string;
  widget: WidgetColumnist;
  isSectionPage?: boolean;
}

const ColumnistWidget: React.FC<ColumnistWidgetProps> = ({
  className = '',
  widget,
  isSectionPage,
}) => {
  const [widgetColumnists, setWidgetColumnists] = useState<ColumnistLayout[]>(
    []
  );
  const { title, columnists } = widget;

  useEffect(() => {
    const getColumns = async () => {
      try {
        const columnistIds = columnists.map((column) => column._ref);
        const allColumnists = await ApiClient.fetchAllColumnistsByColumnRefs(
          columnistIds
        );
        setWidgetColumnists(allColumnists);
      } catch (error) {
        console.error('Error fetching columns:', error);
      }
    };
    getColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ColumnistWidget__container flex flex-col">
      <div className="ColumnistWidget__heading-container flex graebenbach">
        <p className="text-article-body-lg font-700">{title}</p>
        <Button ariaLabel="See all columnists" to="/columnists" wrap={true}>
          <p className="ColumnistWidget__see-all-button text-article-details-sm font-400">
            See All &rarr;&#xFE0E;
          </p>
        </Button>
      </div>
      {widgetColumnists?.map((col) => (
        <Columnist columnist={col} key={col.title} />
      ))}
    </div>
  );
};

export default ColumnistWidget;
