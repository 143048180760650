import React from 'react';
import hashify from 'object-hash';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';
import WidgetSwitch from 'components/WidgetSwitch';

import { LayoutD as ILayoutD } from 'sharedTypes';

interface Props {
  layoutModule: ILayoutD;
  hideSectionIcon?: boolean;
  isSectionPage?: boolean;
}

const LayoutD: React.FC<Props> = ({
  layoutModule,
  hideSectionIcon,
  isSectionPage,
}) => {
  const { alignment, widgets } = layoutModule;

  return (
    <div className="LayoutD content-padding-x sm:pt1_5 inner-content-max-width mxauto">
      <div
        className={`LayoutD__article1-container LayoutD__article1-container--${alignment} layout-story-container`}
      >
        <StoryTeaserSwitch
          teaser={layoutModule.article1TeaserType}
          article={layoutModule.article1}
          hideSectionIcon={hideSectionIcon}
        />
      </div>
      <div
        className={`LayoutD__article2-container LayoutD__article2-container--${alignment} layout-story-container none sm:block md:none lg:block`}
      >
        <StoryTeaserSwitch
          teaser={layoutModule.article2TeaserType}
          article={layoutModule.article2}
          hideSectionIcon={hideSectionIcon}
        />
      </div>
      <div
        className={`LayoutD__article3-container LayoutD__article3-container--${alignment} layout-story-container none sm:block md:none lg:block`}
      >
        <StoryTeaserSwitch
          teaser={layoutModule.article3TeaserType}
          article={layoutModule.article3}
          hideSectionIcon={hideSectionIcon}
        />
      </div>

      <div
        className={`LayoutD__article4-container LayoutD__article4-container--${alignment} layout-story-container none sm:block md:none lg:block`}
      >
        <StoryTeaserSwitch
          teaser={layoutModule.article4TeaserType}
          article={layoutModule.article4}
          hideSectionIcon={hideSectionIcon}
        />
      </div>
      <div
        className={`LayoutD__article5-container LayoutD__article5-container--${alignment} layout-story-container none sm:block md:none lg:block`}
      >
        <StoryTeaserSwitch
          teaser={layoutModule.article5TeaserType}
          article={layoutModule.article5}
          hideSectionIcon={hideSectionIcon}
        />
      </div>
      <div
        className={`LayoutD__article2-article3-container--${alignment} none lg:none flex-col`}
      >
        <div className="layout-story-container flex-grow">
          <StoryTeaserSwitch
            teaser={layoutModule.article2TeaserType}
            article={layoutModule.article2}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
        <div className="layout-story-container mt1_5 flex-grow">
          <StoryTeaserSwitch
            teaser={layoutModule.article3TeaserType}
            article={layoutModule.article3}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
      </div>
      <div
        className={`LayoutD__article3-article4-container--${alignment} relative sm:none`}
      >
        <div className="overflow-hidden">
          <StoryTeaserSwitch
            teaser={layoutModule.article3TeaserType}
            article={layoutModule.article3}
            hideSectionIcon={hideSectionIcon}
            isSplitLayout={true}
          />
        </div>
        <div className="overflow-hidden">
          <StoryTeaserSwitch
            teaser={layoutModule.article4TeaserType}
            article={layoutModule.article4}
            hideSectionIcon={hideSectionIcon}
            isSplitLayout={true}
          />
        </div>
      </div>
      <div
        className={`LayoutD__article2-article5-container--${alignment} relative sm:none`}
      >
        <div className="overflow-hidden">
          <StoryTeaserSwitch
            teaser={layoutModule.article2TeaserType}
            article={layoutModule.article2}
            hideSectionIcon={hideSectionIcon}
            isSplitLayout={true}
          />
        </div>
        <div className="overflow-hidden">
          <StoryTeaserSwitch
            teaser={layoutModule.article5TeaserType}
            article={layoutModule.article5}
            hideSectionIcon={hideSectionIcon}
            isSplitLayout={true}
          />
        </div>
      </div>
      <div
        className={`LayoutD__article4-article5-container--${alignment} none lg:none flex-col`}
      >
        <div className="layout-story-container flex-grow">
          <StoryTeaserSwitch
            teaser={layoutModule.article4TeaserType}
            article={layoutModule.article4}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
        <div className="layout-story-container mt1_5 flex-grow">
          <StoryTeaserSwitch
            teaser={layoutModule.article5TeaserType}
            article={layoutModule.article5}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
      </div>
      <div
        className={`LayoutD__widget-container LayoutD__widget-container--${alignment} layout-widget-container`}
      >
        {widgets && (
          <div className="layout-sticky-widget layout-widgets-container">
            {widgets.map((widget) => {
              return (
                <WidgetSwitch
                  key={hashify(widget)}
                  widget={widget}
                  isSectionPage={isSectionPage}
                  isNarrow={true}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default LayoutD;
