import React from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';

import { Collections4StoriesWidget as ICollections4StoriesWidget } from 'sharedTypes';
import WidgetSwitch from 'components/WidgetSwitch';
import CollectionTitle from 'components/CollectionTitle';
import cx from 'classnames';
import hashify from 'object-hash';

interface Props {
  layoutModule: ICollections4StoriesWidget;
  hideSectionIcon?: boolean;
  isSectionPage?: boolean;
}

const Collections4StoriesWidget: React.FC<Props> = ({
  layoutModule,
  hideSectionIcon,
  isSectionPage,
}) => (
  <div
    className={
      'collections-widget-container content-padding-x inner-content-max-width mxauto pb3'
    }
  >
    <div
      className={cx(
        'collections-widget-container__col-left collections-4-stories-widget',
        {
          'collection-background-grey': !isSectionPage,
          'collection-background-grey-section': isSectionPage,
        }
      )}
    >
      <CollectionTitle
        collection={layoutModule.collectionsLayoutLibrary.collection}
        slugLink={true}
      />
      <div className="collections-4-stories-widget__container  inner-content-max-width mxauto">
        <div className="collections-4-stories-widget__container__article1-container layout-story-container">
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article1TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article1}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
        <div className="collections-4-stories-widget__container__article2-article3-container relative">
          <div className="overflow-hidden non-main-teaser col1">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article2TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article2}
              hideSectionIcon={hideSectionIcon}
              isSplitLayout={true}
            />
          </div>
          <div className="overflow-hidden non-main-teaser col2">
            <StoryTeaserSwitch
              teaser={layoutModule.collectionsLayoutLibrary.article3TeaserType}
              article={layoutModule.collectionsLayoutLibrary.article3}
              hideSectionIcon={hideSectionIcon}
              isSplitLayout={true}
            />
          </div>
        </div>
        <div className="collections-4-stories-widget__container__article2-container layout-story-container none sm:block non-main-teaser">
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article2TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article2}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
        <div className="collections-4-stories-widget__container__article3-container layout-story-container none sm:block non-main-teaser">
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article3TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article3}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
        <div className="collections-4-stories-widget__container__article4-container">
          <StoryTeaserSwitch
            teaser={layoutModule.collectionsLayoutLibrary.article4TeaserType}
            article={layoutModule.collectionsLayoutLibrary.article4}
            hideSectionIcon={hideSectionIcon}
          />
        </div>
      </div>
    </div>
    <div className="collections-widget-container__col-right border-bottom-black">
      {layoutModule.collectionsLayoutLibrary.widgets && (
        <div className="collections-layout-widget-container layout-sticky-widget">
          {layoutModule.collectionsLayoutLibrary.widgets.map((widget) => {
            return (
              <WidgetSwitch
                key={hashify(widget)}
                widget={widget}
                isSectionPage={isSectionPage}
                isNarrow={true}
              />
            );
          })}
        </div>
      )}
    </div>
  </div>
);
export default Collections4StoriesWidget;
