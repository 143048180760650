import React from 'react';

import StoryTeaserSwitch from 'components/StoryTeaserSwitch';

import { LayoutL as ILayoutL } from 'sharedTypes';

interface Props {
  layoutModule: ILayoutL;
  hideSectionIcon?: boolean;
}

const LayoutL: React.FC<Props> = ({ layoutModule, hideSectionIcon }) => (
  <div className="LayoutL content-padding-x sm:pt1_5 inner-content-max-width mxauto">
    <div className="LayoutL__article1-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article1TeaserType}
        article={layoutModule.article1}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutL__article2-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article2TeaserType}
        article={layoutModule.article2}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
    <div className="LayoutL__article3-container layout-story-container">
      <StoryTeaserSwitch
        teaser={layoutModule.article3TeaserType}
        article={layoutModule.article3}
        hideSectionIcon={hideSectionIcon}
      />
    </div>
  </div>
);

export default LayoutL;
